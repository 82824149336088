import React from "react";
import { Checkbox } from "../../component/ui/check-box";
const Checks = [
  {
    id: "1",
    label: "seating",
    name: "9 to 5 Seating",
  },
  {
    id: "2",
    label: "arlod",
    name: "Arold",
  },
  {
    id: "3",
    label: "boss",
    name: "Boss",
  },
  {
    id: "4",
    label: "bostitch",
    name: "Bostitch",
  },
  {
    id: "5",
    label: "bushBusinessFurniture",
    name: " Bush Business Furniture",
  },
  {
    id: "6",
    label: "kidsCarpet",
    name: "Carpets for Kids",
  },
  {
    id: "7",
    label: "euroTech",
    name: "Eurotech",
  },
  {
    id: "8",
    label: "flagshipCarpet",
    name: " Flagship Carpets",
  },
  {
    id: "",
    label: "groupLacasse",
    name: "Groupe Lacasse",
  },
  {
    id: "10",
    label: "HON",
    name: " HON",
  },
  {
    id: "11",
    label: "jontiCraft",
    name: " Jonti-Craft",
  },
  {
    id: "12",
    label: "KFI",
    name: " KFI",
  },
  {
    id: "13",
    label: "lacasse",
    name: " Lacasse",
  },
  {
    id: "14",
    label: "lorell",
    name: " Lorell",
  },
  {
    id: "15",
    label: "Safco",
    name: " Safco",
  },
  {
    id: "16",
    label: "specialT",
    name: " Special-T",
  },
  {
    id: "17",
    label: "TeacherCreatedRes",
    name: "Teacher Created Resources",
  },
  {
    id: "18",
    label: "comfortMakers",
    name: "The ComfortMakers",
  },
  {
    id: "19",
    label: "unitedChair",
    name: " United Chair",
  },
  {
    id: "20",
    label: "vario",
    name: "VARIO",
  },
];
function BrandChecksFilter() {
  return (
    <>
      <form action="" className="flex flex-col gap-2">
        <div className="flex flex-col gap-4">
          {Checks.map((brand) => {
            return (
              <div key={brand.id} className="flex items-center space-x-2">
                <Checkbox id={brand.label} />
                <label
                  htmlFor={brand.label}
                  className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {brand.name}
                </label>
              </div>
            );
          })}
        </div>
        <button className="self-start rounded border bg-gray-100 px-3 py-1 text-sm">
          Apply filter
        </button>
      </form>
    </>
  );
}

export default BrandChecksFilter;
