import React from "react";
import bgImage from "../../assets/images/bg-1.jpg";
import contact from "../../assets/images/smartphone.webp";
function SignUp() {
  return (
    <>
      {" "}
      {/* Sign Up section start */}
      <section className="relative z-[1] h-[450px] w-full">
        <div className="absolute top-0 z-30 h-full w-full bg-black bg-opacity-90"></div>
        <img
          src={bgImage}
          className="absolute top-0 z-0 h-full w-full object-cover"
          alt=""
        />
        <div className="relative z-40 mx-auto flex h-full w-[95%] flex-col items-center justify-center gap-4 md:w-[85%]">
          <img src={contact} alt="" className="h-20 w-20" />
          <p className="font-poppins text-[19px] font-semibold uppercase text-app_white md:text-[26px]">
            Sign up for exclusive coupons and promotions!
          </p>
          <input
            type="text"
            className="h-12 w-full max-w-[680px] rounded-sm border-2 border-app_white bg-transparent px-4 py-2 text-app_white outline-none"
            placeholder="Name"
          />
          <input
            type="email"
            className="h-12 w-full max-w-[680px] rounded-sm border-2 border-app_white bg-transparent px-4 py-2 text-app_white outline-none"
            placeholder="Email"
          />
          <button className="w-full max-w-[680px] bg-app_blue_light py-3 text-app_white">
            SIGN UP
          </button>
        </div>
      </section>
      {/* Sign Up section End */}
    </>
  );
}

export default SignUp;
