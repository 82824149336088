// @ts-ignore
import React from "react";
import { Link } from "react-router-dom";
import caretRight from "../../assets/svg/caret-r.svg";
import { useEffect, useState } from 'react';
import { SERVER_URL } from "../../constants/apiLinks";

// Office Supply start
/* const BinderReport = [
  { id: "", label: "Business Card Books", linkRef: "" },
  { id: "", label: "Data/Hanging Binders", linkRef: "" },
  { id: "", label: "Large Format Binders", linkRef: "" },
  { id: "", label: "Padfolios & Pad Holders", linkRef: "" },
  { id: "", label: "Pocket Folders", linkRef: "" },
  { id: "", label: "Post Binders & Screws", linkRef: "" },
  { id: "", label: "Ring Binders", linkRef: "" },
  { id: "", label: "Sheet Lifters", linkRef: "" },
];
const BusinessAndTrevel = [
  { id: "", label: "Backpacks", linkRef: "" },
  { id: "", label: "Briefcases & Laptop", linkRef: "" },
  { id: "", label: "Bags", linkRef: "" },
  { id: "", label: "Carry/Shopping Bags", linkRef: "" },
  { id: "", label: "Luggage / Travel &", linkRef: "" },
  { id: "", label: "Accessories", linkRef: "" },
];
const Calendars = [
  { id: "", label: "Address/Phone & PW Books", linkRef: "" },
  { id: "", label: "Calendars & Refills", linkRef: "" },
  { id: "", label: "Planners & Refills", linkRef: "" },
];
const DeskOrganizers = [
  { id: "", label: "Bookends/Book Racks", linkRef: "" },
  { id: "", label: "Card Files & Holders", linkRef: "" },
  { id: "", label: "Copyholders", linkRef: "" },
  { id: "", label: "Desk Drawer Organizers", linkRef: "" },
  { id: "", label: "Desk Pads/Desk Pad", linkRef: "" },
  { id: "", label: "Calendars", linkRef: "" },
  { id: "", label: "Desk Shelves", linkRef: "" },
  { id: "", label: "Desktop Accessories", linkRef: "" },
  { id: "", label: "Desktop File Sorters", linkRef: "" },
  { id: "", label: "Desktop/Supply", linkRef: "" },
  { id: "", label: "Drawers", linkRef: "" },
  { id: "", label: "Letter Trays", linkRef: "" },
  { id: "", label: "Pencil Holders", linkRef: "" },
  { id: "", label: "Wall File Organizers", linkRef: "" },
];
const Envelopes = [
  { id: "", label: "Business Envelopes", linkRef: "" },
  { id: "", label: "Clasp Envelopes", linkRef: "" },
  { id: "", label: "Coin Envelopes", linkRef: "" },
  { id: "", label: "Interdepartmental Envelopes", linkRef: "" },
  { id: "", label: "Large Format Catalog Envelopes", linkRef: "" },
  { id: "", label: "Poly Envelopes", linkRef: "" },
  { id: "", label: "Specialty Envelopes", linkRef: "" },
];
const CashHandling = [
  { id: "", label: "Cash Counters", linkRef: "" },
  { id: "", label: "Cash Drawers & Storage", linkRef: "" },
  { id: "", label: "Cash Handling Supplies", linkRef: "" },
  { id: "", label: "Check Writers", linkRef: "" },
  { id: "", label: "Coin / Currency Sorters", linkRef: "" },
];
const CardsStationery = [
  { id: "", label: "Business Cards", linkRef: "" },
  { id: "", label: "Cards & Stationery Sets", linkRef: "" },
  { id: "", label: "Greeting Cards", linkRef: "" },
  { id: "", label: "ID Cards", linkRef: "" },
  { id: "", label: "Index Cards & Accessories", linkRef: "" },
  { id: "", label: "Invitations", linkRef: "" },
  { id: "", label: "Note Cards", linkRef: "" },
  { id: "", label: "Postcards", linkRef: "" },
  { id: "", label: "Printed Stationery", linkRef: "" },
  { id: "", label: "Tent / Placement", linkRef: "" },
];
const Labels = [
  { id: "", label: "Adhesive Notes & Dispensers", linkRef: "" },
  { id: "", label: "Binder / Bulldog Clips", linkRef: "" },
  { id: "", label: "Call Bells", linkRef: "" },
  { id: "", label: "Correction Supplies", linkRef: "" },
  { id: "", label: "ID Badges & Supplies", linkRef: "" },
  { id: "", label: "Magnifiers", linkRef: "" },
  { id: "", label: "Mailbox/Suggestion Boxes", linkRef: "" },
  { id: "", label: "Paper Clips", linkRef: "" },
  { id: "", label: "Paper Fasteners & Clamps", linkRef: "" },
  { id: "", label: "Paper Punches", linkRef: "" },
  { id: "", label: "Paper Trimmers & Accessories", linkRef: "" },
  { id: "", label: "Plastic Holders & Pockets", linkRef: "" },
  { id: "", label: "Plastic/Rubber Bands", linkRef: "" },
  { id: "", label: "Push Pins & Tacks", linkRef: "" },
  { id: "", label: "Scissors", linkRef: "" },
  { id: "", label: "Signs & Nameplates", linkRef: "" },
  { id: "", label: "Staplers", linkRef: "" },
  { id: "", label: "Staples & Staple", linkRef: "" },
  { id: "", label: "Removers", linkRef: "" },
  { id: "", label: "Tags & Tickets", linkRef: "" },
  { id: "", label: "Wall Clips", linkRef: "" },
];
const GeneralSupplies = [
  { id: "", label: "Classification Folders", linkRef: "" },
  { id: "", label: "End Tab Folders", linkRef: "" },
  { id: "", label: "Expanding Files", linkRef: "" },
  { id: "", label: "Fastener Folders", linkRef: "" },
  { id: "", label: "File Folders", linkRef: "" },
  { id: "", label: "Filing Accessories", linkRef: "" },
  { id: "", label: "Filing Guides", linkRef: "" },
  { id: "", label: "Filing Jackets & Envelopes", linkRef: "" },
  { id: "", label: "Hanging Folders & Accessories", linkRef: "" },
  { id: "", label: "Index Accessories & Page Markers", linkRef: "" },
  { id: "", label: "Indexing Flags", linkRef: "" },
];
const FoldersFiling = [
  { id: "", label: "Classification Folders", linkRef: "" },
  { id: "", label: "End Tab Folders", linkRef: "" },
  { id: "", label: "Expanding Files", linkRef: "" },
  { id: "", label: "Fastener Folders", linkRef: "" },
  { id: "", label: "File Folders", linkRef: "" },
  { id: "", label: "Filing Accessories", linkRef: "" },
  { id: "", label: "Filing Guides", linkRef: "" },
  { id: "", label: "Filing Jackets & Envelopes", linkRef: "" },
  { id: "", label: "Hanging Folders & Accessories", linkRef: "" },
  { id: "", label: "Index Accessories & Page Markers", linkRef: "" },
  { id: "", label: "Indexing Flags", linkRef: "" },
];
const MailingShipping = [
  { id: "", label: "Mailers ", linkRef: "" },
  { id: "", label: "Mailing/Document Tubes", linkRef: "" },
  { id: "", label: "Mailroom Equipment & SuppliesR", linkRef: "" },
  { id: "", label: "Packing Slip Forms/Envelopes", linkRef: "" },
  { id: "", label: "Packing Supplies", linkRef: "" },
  { id: "", label: "Packing Tape & Dispensers", linkRef: "" },
];
const FormsRecordkeeping = [
  { id: "", label: "Account / Record Books ", linkRef: "" },
  { id: "", label: "Automobile Log Books ", linkRef: "" },
  { id: "", label: "Bill of Landing Forms ", linkRef: "" },
  { id: "", label: "Business/Legal Forms ", linkRef: "" },
  { id: "", label: "Certificates & Seals ", linkRef: "" },
  { id: "", label: "Claim/Medical Forms ", linkRef: "" },
  { id: "", label: "Clipboards & Form Holders ", linkRef: "" },
  { id: "", label: "Correspondence Forms ", linkRef: "" },
  { id: "", label: "Employment Forms ", linkRef: "" },
  { id: "", label: "Expense/Statement Forms ", linkRef: "" },
  { id: "", label: "Gift Certificates ", linkRef: "" },
  { id: "", label: "Inventory Forms & Tags ", linkRef: "" },
  { id: "", label: "Invoice/Work Order Forms ", linkRef: "" },
  { id: "", label: "Minute & Logbooks ", linkRef: "" },
  { id: "", label: "Receipts/Petty Cash Book ", linkRef: "" },
  { id: "", label: "Receiving & Purchase Order Forms ", linkRef: "" },
  { id: "", label: "Sales Forms ", linkRef: "" },
  { id: "", label: "Tax Forms ", linkRef: "" },
  { id: "", label: "Visitor Registry Books ", linkRef: "" },
];
const StampsStamp = [
  { id: "", label: "Account / Record Books ", linkRef: "" },
  { id: "", label: "Automobile Log Books ", linkRef: "" },
  { id: "", label: "Bill of Landing Forms ", linkRef: "" },
  { id: "", label: "Business/Legal Forms ", linkRef: "" },
  { id: "", label: "Certificates & Seals ", linkRef: "" },
  { id: "", label: "Claim/Medical Forms ", linkRef: "" },
  { id: "", label: "Clipboards & Form Holders ", linkRef: "" },
  { id: "", label: "Correspondence Forms ", linkRef: "" },
  { id: "", label: "Employment Forms ", linkRef: "" },
  { id: "", label: "Expense/Statement Forms ", linkRef: "" },
  { id: "", label: "Gift Certificates ", linkRef: "" },
  { id: "", label: "Inventory Forms & Tags ", linkRef: "" },
  { id: "", label: "Invoice/Work Order Forms ", linkRef: "" },
  { id: "", label: "Minute & Logbooks ", linkRef: "" },
  { id: "", label: "Receipts/Petty Cash Book ", linkRef: "" },
  { id: "", label: "Receiving & Purchase Order Forms ", linkRef: "" },
  { id: "", label: "Sales Forms ", linkRef: "" },
  { id: "", label: "Tax Forms ", linkRef: "" },
  { id: "", label: "Visitor Registry Books ", linkRef: "" },
];
const Writing = [
  { id: "", label: "Erasers", linkRef: "" },
  { id: "", label: "Grips & Cushions ", linkRef: "" },
  { id: "", label: "Highlighters", linkRef: "" },
  { id: "", label: "Markers & Dry-Erase", linkRef: "" },
  { id: "", label: "Pen & Pencil Sets", linkRef: "" },
  { id: "", label: "Pencils", linkRef: "" },
  { id: "", label: "Pens", linkRef: "" },
  { id: "", label: "Refills", linkRef: "" },
  { id: "", label: "Sharpeners", linkRef: "" },
];
const PaperWritingPads = [
  { id: "", label: "Copy & Printer Paper", linkRef: "" },
  { id: "", label: "Filler Paper", linkRef: "" },
  { id: "", label: "Graph Paper", linkRef: "" },
  { id: "", label: "Journals", linkRef: "" },
  { id: "", label: "Letter, Legal & Jr. Pads", linkRef: "" },
  { id: "", label: "Note Pads", linkRef: "" },
  { id: "", label: "Notebooks", linkRef: "" },
  { id: "", label: "Photo Paper", linkRef: "" },
  { id: "", label: "Register / Thermal Receipts", linkRef: "" },
  { id: "", label: "Security Paper", linkRef: "" },
  { id: "", label: "Specialty Paper", linkRef: "" },
];
const Storage = [
  { id: "", label: "Copy & Printer Paper", linkRef: "" },
  { id: "", label: "Filler Paper", linkRef: "" },
  { id: "", label: "Graph Paper", linkRef: "" },
  { id: "", label: "Journals", linkRef: "" },
  { id: "", label: "Letter, Legal & Jr. Pads", linkRef: "" },
  { id: "", label: "Note Pads", linkRef: "" },
  { id: "", label: "Notebooks", linkRef: "" },
  { id: "", label: "Photo Paper", linkRef: "" },
  { id: "", label: "Register / Thermal Receipts", linkRef: "" },
  { id: "", label: "Security Paper", linkRef: "" },
  { id: "", label: "Specialty Paper", linkRef: "" },
];
const TapeAdhesives = [
  { id: "", label: "All-Purpose Glue", linkRef: "" },
  { id: "", label: "Book Tapes", linkRef: "" },
  { id: "", label: "Double-Sided Tape", linkRef: "" },
  { id: "", label: "Duct Tape", linkRef: "" },
  { id: "", label: "Electrical Tape", linkRef: "" },
  { id: "", label: "Filament Tape", linkRef: "" },
  { id: "", label: "General Purpose/Office Tape", linkRef: "" },
  { id: "", label: "Glue Guns & Accessories", linkRef: "" },
  { id: "", label: "Glue Sticks", linkRef: "" },
  { id: "", label: "Hook & Loop", linkRef: "" },
  { id: "", label: "Magnetic Tape", linkRef: "" },
  { id: "", label: "Masking Tape", linkRef: "" },
  { id: "", label: "Mounting Tape & Putty", linkRef: "" },
  { id: "", label: "Padding Compounds", linkRef: "" },
  { id: "", label: "Rubber Cement", linkRef: "" },
  { id: "", label: "Spray Adhesives", linkRef: "" },
];
const BinderAccessories = [
  { id: "", label: "Binder Cases & Storage", linkRef: "" },
  { id: "", label: "Binder Labels", linkRef: "" },
  { id: "", label: "Binder Pockets", linkRef: "" },
  { id: "", label: "Binder Refill Pages", linkRef: "" },
  { id: "", label: "Binder Spine", linkRef: "" },
  { id: "", label: "Inserts/Labels", linkRef: "" },
  { id: "", label: "Index Divider", linkRef: "" },
  { id: "", label: "Hole/Binder", linkRef: "" },
  { id: "", label: "Reinforcement Strips", linkRef: "" },
]; */
// Office Supply End

// Breakroom start
/* const BeverageDispensers = [
  { id: "", label: "Beverage Dispensers", linkRef: "" },
  { id: "", label: "Can Openers", linkRef: "" },
  { id: "", label: "Microwave Ovens", linkRef: "" },
  { id: "", label: "Refrigerators / Coolers / Freezers", linkRef: "" },
  { id: "", label: "Toasters & Toaster Ovens", linkRef: "" },
];
const Beverages = [
  { id: "", label: "Hot Chocolate/Cocoa", linkRef: "" },
  { id: "", label: "Juice", linkRef: "" },
  { id: "", label: "Sodas & Sports Drinks", linkRef: "" },
  { id: "", label: "Water", linkRef: "" },
];
const CoffeeCoffeeSupplies = [
  { id: "", label: "Coffee", linkRef: "" },
  { id: "", label: "Coffee Filters", linkRef: "" },
  { id: "", label: "Coffee Makers", linkRef: "" },
  { id: "", label: "Coffee Pots / Servers", linkRef: "" },
  { id: "", label: "Coffee Warmers", linkRef: "" },
  { id: "", label: "Creamers", linkRef: "" },
  { id: "", label: "Stirrers", linkRef: "" },
  { id: "", label: "Water Filters", linkRef: "" },
];
const FoodCondiments = [
  { id: "", label: "Food & Condiments", linkRef: "" },
  { id: "", label: "Candy & Gum", linkRef: "" },
  { id: "", label: "Condiments", linkRef: "" },
  { id: "", label: "Snacks", linkRef: "" },
];
const PlatesCupsCutlery = [
  { id: "", label: "Beverage Straws", linkRef: "" },
  { id: "", label: "Beverage Tumblers", linkRef: "" },
  { id: "", label: "Bowls", linkRef: "" },
  { id: "", label: "Cup Dispensers / Organizers", linkRef: "" },
  { id: "", label: "Cutlery", linkRef: "" },
  { id: "", label: "Drinking Cups", linkRef: "" },
  { id: "", label: "Plates", linkRef: "" },
  { id: "", label: "Toothpicks", linkRef: "" },
];
const FoodserviceSupplies = [
  { id: "", label: "Breakroom", linkRef: "" },
  { id: "", label: "Organizers", linkRef: "" },
  { id: "", label: "Cooking Utensils", linkRef: "" },
  { id: "", label: "Food Storage", linkRef: "" },
  { id: "", label: "Napkin Dispensers", linkRef: "" },
  { id: "", label: "Paper Napkins", linkRef: "" },
  { id: "", label: "Table Covers", linkRef: "" },
  { id: "", label: "Water Pitchers", linkRef: "" },
];
const ClothingProtection = [
  { id: "", label: "Coveralls", linkRef: "" },
  { id: "", label: "Hairnets", linkRef: "" },
  { id: "", label: "Overall Bibs & Aprons", linkRef: "" },
  { id: "", label: "Protective Gear", linkRef: "" },
  { id: "", label: "Safety Jackets / Vests", linkRef: "" },
];
const ErgonomicSupport = [
  { id: "", label: "Back Supports", linkRef: "" },
  { id: "", label: "Wrist Supports", linkRef: "" },
];
const FireSmokeProtection = [
  { id: "", label: "Fire Extinguishers", linkRef: "" },
  { id: "", label: "Smoke / Carbon Monoxide Detectors", linkRef: "" },
];
const FirstAidKits = [
  { id: "", label: "Bandages & Wraps", linkRef: "" },
  { id: "", label: "Eyewash", linkRef: "" },
  { id: "", label: "First Aid Kits", linkRef: "" },
  { id: "", label: "Wound Care", linkRef: "" },
];
const OccupationalWear = [{ id: "", label: "Staff/Lab Coats", linkRef: "" }];
const Gloves = [
  { id: "", label: "Coated Gloves", linkRef: "" },
  { id: "", label: "Exam Gloves", linkRef: "" },
  { id: "", label: "General Purpose", linkRef: "" },
  { id: "", label: "Gloves", linkRef: "" },
  { id: "", label: "Glove Box Holders", linkRef: "" },
  { id: "", label: "Work Gloves", linkRef: "" },
];
const HealthcareSupplies = [
  { id: "", label: "Air Respirators & Accessories", linkRef: "" },
  { id: "", label: "Blood Pressure Monitors", linkRef: "" },
  { id: "", label: "Defibrillators & Accessories", linkRef: "" },
  { id: "", label: "Exam Room Supplies", linkRef: "" },
  { id: "", label: "Medical Scales", linkRef: "" },
  { id: "", label: "Medical Spill Kits", linkRef: "" },
  { id: "", label: "Medications", linkRef: "" },
  { id: "", label: "Screening & Home Tests", linkRef: "" },
  { id: "", label: "Stethoscopes", linkRef: "" },
  { id: "", label: "Thermometers", linkRef: "" },
  { id: "", label: "Tongue Depressors", linkRef: "" },
  { id: "", label: "Walkers & Crutches", linkRef: "" },
];
const Masks = [
  { id: "", label: "Multipurpose Masks", linkRef: "" },
  { id: "", label: "N95 Masks", linkRef: "" },
  { id: "", label: "Surgical Masks", linkRef: "" },
]; */
// Breakroom End
// School supplies start
/* const ArtAndCraft = [
  { id: "1", label: "Art Markers", linkRef: "" },
  { id: "2", label: "Arts & Crafts Kits/Accessories", linkRef: "" },
  { id: "3", label: "Bags", linkRef: "" },
  { id: "4", label: "Beads & Rhinestones", linkRef: "" },
  { id: "5", label: "Blocks", linkRef: "" },
  { id: "6", label: "Brush Tip Pens", linkRef: "" },
  { id: "7", label: "Buttons", linkRef: "" },
  { id: "8", label: "Chalks", linkRef: "" },
  { id: "9", label: "China Markers", linkRef: "" },
  { id: "10", label: "Clothespins/Clotheslin", linkRef: "" },
  { id: "11", label: "Coloring Books", linkRef: "" },
  { id: "12", label: "Construction Paper", linkRef: "" },
  { id: "13", label: "Craft Glue", linkRef: "" },
  { id: "14", label: "Craft Sticks", linkRef: "" },
  { id: "15", label: "Crayons", linkRef: "" },
  { id: "16", label: "Dough Gadgets", linkRef: "" },
  { id: "17", label: "Drawing Paper & Sketch Pad", linkRef: "" },
  { id: "18", label: "Feathers", linkRef: "" },
  { id: "19", label: "Felt", linkRef: "" },
  { id: "20", label: "Foam", linkRef: "" },
  { id: "21", label: "Fun Buckets", linkRef: "" },
  { id: "22", label: "Glitter", linkRef: "" },
  { id: "23", label: "Modeling Clay", linkRef: "" },
  { id: "24", label: "Oil Pastels / Pastel Sticks", linkRef: "" },
  { id: "25", label: "Paint", linkRef: "" },
  { id: "26", label: "Paint Accessories", linkRef: "" },
  { id: "27", label: "Paint Dispensing Pumps", linkRef: "" },
  { id: "28", label: "Paper Mache", linkRef: "" },
  { id: "29", label: "Pipe Cleaners / Bumps / Stems", linkRef: "" },
  { id: "30", label: "Plaster Crafts", linkRef: "" },
  { id: "31", label: "Pom Poms", linkRef: "" },
  { id: "32", label: "Project Boards", linkRef: "" },
  { id: "33", label: "Puzzles", linkRef: "" },
  { id: "34", label: "Sequins and Spangles", linkRef: "" },
  { id: "35", label: "Shapes", linkRef: "" },
  { id: "36", label: "Specialty Marking & Colored Pencils", linkRef: "" },
  { id: "37", label: "Stain Remover - Crayon,Marker,Ink", linkRef: "" },
  { id: "38", label: "Stencils", linkRef: "" },
  { id: "29", label: "Wiggly Eyes/Craft Eyes", linkRef: "" },
  { id: "40", label: "Wood Crafts", linkRef: "" },
  { id: "41", label: "Yarn", linkRef: "" },
];
const BasicSupplies = [
  { id: "3", label: "Pencil Boxes", linkRef: "" },
  { id: "3", label: "Rulers", linkRef: "" },
  { id: "3", label: "Storage Pouches", linkRef: "" },
];
const ClassroomDecor = [
  { id: "", label: "Balloons", linkRef: "" },
  { id: "", label: "Bulletin Board Sets & Trim", linkRef: "" },
  { id: "", label: "Classroom Decor Accents/Banners", linkRef: "" },
  { id: "", label: "Classroom Organizers", linkRef: "" },
  { id: "", label: "Letters & Numbers", linkRef: "" },
  { id: "", label: "Magnetic Shapes & Strips", linkRef: "" },
  { id: "", label: "Posters", linkRef: "" },
];
const DraftingSupplies = [
  { id: "", label: "Compass", linkRef: "" },
  { id: "", label: "Drafting Supplies", linkRef: "" },
  { id: "", label: "Graphic Tapes", linkRef: "" },
  { id: "", label: "Protractors & Triangles", linkRef: "" },
  { id: "", label: "Templates", linkRef: "" },
];
const GamesSports = [
  { id: "", label: "Learning Toys & Blocks", linkRef: "" },
  { id: "", label: "Pretend Play", linkRef: "" },
  { id: "", label: "Sports Equipment", linkRef: "" },
];
const TeachingAidsSupplies = [
  { id: "", label: "Digital Notepads", linkRef: "" },
  { id: "", label: "Educational Record Books", linkRef: "" },
  { id: "", label: "Flash Cards & Card Games", linkRef: "" },
  { id: "", label: "Games & Puzzles", linkRef: "" },
  { id: "", label: "Handwriting Paper", linkRef: "" },
  { id: "", label: "Learning Boards", linkRef: "" },
  { id: "", label: "Learning Charts & Accessories", linkRef: "" },
  { id: "", label: "Reference Books Stickers", linkRef: "" },
  { id: "", label: "Student Certificates / Awards", linkRef: "" },
  { id: "", label: "Teaching Calendars & Planners", linkRef: "" },
]; */
// School supplies End
// Technolgy start
/* const BusinessAndMech = [
  { id: "", label: "Binding Machine Supplies", linkRef: "" },
  { id: "", label: "Binding Machines", linkRef: "" },
  { id: "", label: "Calculators", linkRef: "" },
  { id: "", label: "Digital Recorders & Accessories", linkRef: "" },
  { id: "", label: "Label Maker Supplies", linkRef: "" },
  { id: "", label: "Label Printers / Labelers", linkRef: "" },
  { id: "", label: "Laminating Supplies", linkRef: "" },
  { id: "", label: "Laminators", linkRef: "" },
  { id: "", label: "POS Systems", linkRef: "" },
  { id: "", label: "Pricing Guns", linkRef: "" },
  { id: "", label: "Shredders & Accessories", linkRef: "" },
  { id: "", label: "Time Clocks & Accessories", linkRef: "" },
];
const ComputerSupplies = [
  { id: "", label: "Computer / Laptop Accessories", linkRef: "" },
  { id: "", label: "Discs & Media", linkRef: "" },
  { id: "", label: "Hard Drives", linkRef: "" },
  { id: "", label: "Keyboards & Mice", linkRef: "" },
  { id: "", label: "Memory & USB Drives", linkRef: "" },
  { id: "", label: "Monitor Arms & Stands", linkRef: "" },
  { id: "", label: "Monitors", linkRef: "" },
  { id: "", label: "Networking & WiFi Accessories", linkRef: "" },
  { id: "", label: "Power Cords & Surge Protectors", linkRef: "" },
  { id: "", label: "Screen Filters & Protectors", linkRef: "" },
  { id: "", label: "UPS Backup Systems", linkRef: "" },
];
const ConsumerElectronics = [
  { id: "", label: "Audio / Video Cables", linkRef: "" },
  { id: "", label: "Cameras", linkRef: "" },
  { id: "", label: "Consumer Electronics & Accessories", linkRef: "" },
  { id: "", label: "Electronics Cleaning Supplies", linkRef: "" },
  { id: "", label: "Headphones", linkRef: "" },
  { id: "", label: "Laser Pointers", linkRef: "" },
  { id: "", label: "Phone Systems", linkRef: "" },
  { id: "", label: "Projectors & Accessories", linkRef: "" },
  { id: "", label: "Speakers & Audio", linkRef: "" },
  { id: "", label: "TVs & Accessories", linkRef: "" },
  { id: "", label: "CompTwo-way & NOAA Radiosuter", linkRef: "" },
];
const PrintersScanners = [
  { id: "", label: "Fax Machines", linkRef: "" },
  { id: "", label: "Printers & Multifunction Machines", linkRef: "" },
  { id: "", label: "Printing Supplies", linkRef: "" },
  { id: "", label: "Scanners", linkRef: "" },
]; */
// Technolgy End
//  Janitorial & Facility Supplies start
/* const AirOdorControl = [
  { id: "", label: "Air Freshener", linkRef: "" },
  { id: "", label: "Dispensers", linkRef: "" },
  { id: "", label: "Air Fresheners", linkRef: "" },
  { id: "", label: "Air Purifier Filters", linkRef: "" },
  { id: "", label: "Air Purifiers", linkRef: "" },
  { id: "", label: "Deodorizers", linkRef: "" },
];
const CleaningSupplies = [
  { id: "", label: "Adhesive Removers", linkRef: "" },
  { id: "", label: "Cleaning Wipes", linkRef: "" },
  { id: "", label: "Dish/Dishwasher Detergents", linkRef: "" },
  { id: "", label: "Disinfectants", linkRef: "" },
  { id: "", label: "Drain Cleaners", linkRef: "" },
  { id: "", label: "Glass Cleaners", linkRef: "" },
  { id: "", label: "Laundry Cleaning & Supplies", linkRef: "" },
  { id: "", label: "Multipurpose Cleaners", linkRef: "" },
  { id: "", label: "Restroom & Toilet Cleaners", linkRef: "" },
  { id: "", label: "Specialty Cleaners", linkRef: "" },
];
const CleaningTools = [
  { id: "", label: "Cleaning Tools", linkRef: "" },
  { id: "", label: "Brooms & Sweepers", linkRef: "" },
  { id: "", label: "Buckets & Wringers", linkRef: "" },
  { id: "", label: "Cleaning Pad Holders", linkRef: "" },
  { id: "", label: "Debris Nabbers", linkRef: "" },
  { id: "", label: "Dust Mop Frames", linkRef: "" },
  { id: "", label: "Dust Pans", linkRef: "" },
  { id: "", label: "Dusters", linkRef: "" },
  { id: "", label: "Mops & Refills", linkRef: "" },
  { id: "", label: "Poles & Handles", linkRef: "" },
  { id: "", label: "Scrub Brushes", linkRef: "" },
  { id: "", label: "Sponqes, Cleaning Pads Wipers", linkRef: "" },
  { id: "", label: "Sprayer Dispensing & Accessories", linkRef: "" },
  { id: "", label: "Squeegees / Scrappers", linkRef: "" },
  { id: "", label: "Toilet Bowl Brushes", linkRef: "" },
];
const ClimateControl = [
  { id: "", label: "Fans", linkRef: "" },
  { id: "", label: "Heaters", linkRef: "" },
  { id: "", label: "Humidifiers", linkRef: "" },
];
const FacilitySupplies = [
  { id: "", label: "Crowd Control", linkRef: "" },
  { id: "", label: "Ladders & Step Stools", linkRef: "" },
  { id: "", label: "Marking / Industrial Paint", linkRef: "" },
  { id: "", label: "Snow / Ice Melt", linkRef: "" },
];
const Lighting = [
  { id: "", label: "Flashlights", linkRef: "" },
  { id: "", label: "Lamps / LED Lighting", linkRef: "" },
  { id: "", label: "Lanterns", linkRef: "" },
  { id: "", label: "Light Bulbs", linkRef: "" },
  { id: "", label: "Task Lights", linkRef: "" },
];
const PestControl = [
  { id: "", label: "Pesticides", linkRef: "" },
  { id: "", label: "Repellents", linkRef: "" },
];
const FacilitySigns = [
  { id: "", label: "Safety Data Sheets - SDS", linkRef: "" },
  { id: "", label: "Safety Signs", linkRef: "" },
];
const JanitorialEquipment = [
  { id: "", label: "Cleaning Tool", linkRef: "" },
  { id: "", label: "Organizers", linkRef: "" },
  { id: "", label: "Cleaning/Utility Carts", linkRef: "" },
];
const ToolsEquipment = [
  { id: "", label: "Door Accessories", linkRef: "" },
  { id: "", label: "Hand Trucks & Dollies", linkRef: "" },
  { id: "", label: "Outdoor Equipment", linkRef: "" },
  { id: "", label: "Screwdrivers", linkRef: "" },
  { id: "", label: "Tape Measures/ Guages", linkRef: "" },
  { id: "", label: "Tool Kits", linkRef: "" },
  { id: "", label: "Tool Storage & Organization", linkRef: "" },
  { id: "", label: "Utility Knives & Cutters", linkRef: "" },
  { id: "", label: "Utility Replacement Blades", linkRef: "" },
  { id: "", label: "Organizers", linkRef: "" },
];
const RestroomSupplies = [
  { id: "", label: "Changing Tables / Liners", linkRef: "" },
  { id: "", label: "Disposable Liners", linkRef: "" },
  { id: "", label: "Faucets", linkRef: "" },
  { id: "", label: "Flush Systems", linkRef: "" },
  { id: "", label: "Hand Dryers", linkRef: "" },
  { id: "", label: "Hand Soaps/Cleaners", linkRef: "" },
  { id: "", label: "Personal Hygiene Supplies", linkRef: "" },
  { id: "", label: "Plungers", linkRef: "" },
  { id: "", label: "Receptacles", linkRef: "" },
  { id: "", label: "Restroom Mats", linkRef: "" },
  { id: "", label: "Sanitary Receptacle Dispenser", linkRef: "" },
  { id: "", label: "Shower Accessories", linkRef: "" },
  { id: "", label: "Toilet Cover Dispensers", linkRef: "" },
  { id: "", label: "Toilet Seat Covers", linkRef: "" },
  { id: "", label: "Urinal Deodorant Blocks", linkRef: "" },
  { id: "", label: "Organizers", linkRef: "" },
  { id: "", label: "Urinal Screens / Mats", linkRef: "" },
];
const Security = [
  { id: "", label: "Alert Chimes", linkRef: "" },
  { id: "", label: "Key Boxes & Storage", linkRef: "" },
  { id: "", label: "Key Tags", linkRef: "" },
  { id: "", label: "Lockout Kits", linkRef: "" },
  { id: "", label: "Locks", linkRef: "" },
  { id: "", label: "Safety Horn", linkRef: "" },
];
const WasteRecycling = [
  { id: "", label: "Medical Waste Bags & Containers", linkRef: "" },
  { id: "", label: "Recycling Bins", linkRef: "" },
  { id: "", label: "Smoking Receptacles", linkRef: "" },
  { id: "", label: "Trash Bags & Liners", linkRef: "" },
  { id: "", label: "Waste Containers & Lids", linkRef: "" },
];
const personalCareHygiene = [
  { id: "", label: "Cotton Balls & Swabs", linkRef: "" },
  { id: "", label: "Feminine Hygiene", linkRef: "" },
  { id: "", label: "Products", linkRef: "" },
  { id: "", label: "Lens Cleaners", linkRef: "" },
];
const SkinBodyCare = [
  { id: "", label: "Hand Sanitizers", linkRef: "" },
  { id: "", label: "Liquid Soap/Sanitizer Dispensers", linkRef: "" },
  { id: "", label: "Skin Wipes", linkRef: "" },
  { id: "", label: "Skin/Body Lotions", linkRef: "" },
]; */
//  Janitorial & Facility Supplies End

function MegaMenu() {
  const [categories, setCategories] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${SERVER_URL}/api/categoryforfilter`);
        if (!response.ok) throw new Error('Failed to fetch categories');

        const data = await response.json();
        setCategories(data.data);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  return (
    <>
      <div className="antialiased  flex flex-col gap-2 z-[999] p-4 w-full">
      {categories.map((parentCategory:any) => (
        <div key={parentCategory._id} className="group inline-block self-start">
          <Link
            to={`/product-category/${parentCategory.slug}`}
            aria-haspopup="true"
            aria-controls={parentCategory.name}
            className="outline-none focus:outline-none  px-3 py-1 flex items-center min-w-32"
          >
            <span className="pr-1 flex-1">{parentCategory.name}</span>
            <span>
              <img src={caretRight} className="w-5 h-5" alt="" />
            </span>
          </Link>
          <ul
            id={parentCategory.name}
            aria-hidden="true"
            className="bg-white border rounded-sm transform scale-0 group-hover:scale-100 absolute 
              transition duration-150 ease-in-out origin-top min-w-32 z-[999] divide-y-[1px] "
          >
            {parentCategory.secondLevelCategories?.map((childCategory:any) => (
            <li className="rounded-sm relative px-3 py-4 hover:bg-gray-100">
              <Link key={childCategory._id} to={`/product-category/${childCategory.slug}`}>
                <div
                  aria-haspopup="true"
                  aria-controls={parentCategory.name}
                  className="w-full text-left flex items-center outline-none focus:outline-none"
                >
                  <span className="pr-1 flex-1">{childCategory.name}</span>
                  <span className="mr-auto">
                    <img src={caretRight} className="w-5 h-5" alt="" />
                  </span>
                </div>
              </Link>
              <ul
                id={childCategory.name}
                aria-hidden="true"
                className="bg-white border rounded-sm absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left min-w-40 divide-y-[1px] py-1"
              >
                {childCategory.thirdLevelCategories&&childCategory.thirdLevelCategories.map((item:any) => {
                  return (
                    <li
                      key={item.id}
                      className="relative px-3 py-3 hover:text-app_primary"
                    >
                      <Link
                        to={`/product-category/${item.slug}`}
                        className="w-full text-left flex items-center outline-none focus:outline-none"
                      >
                        <span className="pr-1 flex-1">{item.name}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
            ))}
          </ul>
        </div>
      ))}
       
      </div>
    </>
  );
}

export default MegaMenu;
