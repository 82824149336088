import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import ApplyNow from "../../ApplyNow/ApplyNow";

const Third = ({sec4,content}) => {
  return (
    <>
     {sec4&&sec4.status?(
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${sec4&&sec4.image?sec4.image:""})`,
      }}
      className="  bg-fixed bg-cover text-white  py-20 px-[12%]"
    >
      <h1 className="font-mandhor text-[120px] text-[#6EC1E470]">{content&&content[0].title?content[0].title:""}</h1>

      <h2 className="text-white text-4xl md:text-5xl  lg:text-5xl -mt-8 ">
        {sec4&&sec4.title?sec4.title:""}
      </h2>

      <div className="flex items-center text-2xl my-5 mb-2">
        <div className="">
          <FaCheckCircle className="text-msky mr-2 " />
        </div>
        <p className="">
         {content&&content[1].title?content[1].title:""}
        </p>
      </div>

      <div className="flex items-center text-2xl">
        <div>
          <FaCheckCircle className="text-msky mr-2 " />
        </div>
        <p className="">
        {content&&content[2].title?content[2].title:""}
        </p>
      </div>

      <h2 className="my-6 text-white text-3xl  font-semibold">
        {content&&content[3].title?content[3].title:""}
      </h2>

      <p className="my-6 ">
      {content&&content[3].description?content[3].description:""}
      </p>

      <ApplyNow />
    </div>
     ):("")}
    </>
  );
};

export default Third;
