import React, { useReducer, useEffect, useState, useRef } from "react";
import cr from "../../assets/svg/cr.svg";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../../constants/apiLinks";

type StateType = {
  [key: string]: boolean;
};

type ActionType = {
  type: "TOGGLE";
  section: string;
};

interface Category {
  id: string;
  name: string;
  slug: string;
  status: number;
  children?: Category[];
}

// Reducer to handle the toggle state based on dynamic keys
const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case "TOGGLE":
      return { ...state, [action.section]: !state[action.section] };
    default:
      return state;
  }
};


const AccordionCustom: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [state, dispatch] = useReducer(reducer, {});

  // useRef to cache the API response across component mounts
  const cachedCategories = useRef<Category[] | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      // If data is already cached, use it directly
      if (cachedCategories.current) {
        setCategories(cachedCategories.current);
        setLoading(false);
        return;
      }

      // Fetch data if not cached
      try {
        const response = await fetch(`${SERVER_URL}/api/categoryorganize`);
        if (!response.ok) throw new Error("Failed to fetch categories");

        const data: Category[] = await response.json();
        cachedCategories.current = data; // Cache the data
        setCategories(data);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="flex flex-col text-[15px] bg-gray-200 text-app_black font-Noto font-normal">
      {categories.map((parentCategory) => (
        // Parent Accordion
        <div key={parentCategory.id} className="flex flex-col border-b border-gray-300">
          <div
            className={`flex gap-2 justify-between px-4 items-center cursor-pointer py-3 ${
              state[parentCategory.id] ? "bg-white" : ""
            }`}
            onClick={() => dispatch({ type: "TOGGLE", section: parentCategory.id })}
          >
             <span><Link
                          key={parentCategory.id}
                          to={`/product-category/${parentCategory.slug}`}>{parentCategory.name}</Link></span>
            <span className="transition-all delay-150 duration-200 text-[24px]">
              {state[parentCategory.id] ? "-" : "+"}
            </span>
          </div>

          {state[parentCategory.id] && (
            <div className="flex flex-col">
              {parentCategory.children?.map((childCategory) => (
                // Child Accordion
                <div key={childCategory.id} className="flex flex-col border-b border-gray-300">
                  <div
                    className={`flex gap-2 px-4 py-2 items-center justify-between cursor-pointer ${
                      state[childCategory.id] ? "bg-gray-100" : ""
                    }`}
                    onClick={() => dispatch({ type: "TOGGLE", section: childCategory.id })}
                  >
                    <div className="flex gap-2">
                      <img src={cr} className="w-6 h-6" alt="" />
                      <span><Link
                          key={childCategory.id}
                          to={`/product-category/${childCategory.slug}`}>{childCategory.name}</Link></span>
                    </div>
                    <span className="transition-all delay-150 duration-200 text-[24px]">
                      {state[childCategory.id] ? "-" : "+"}
                    </span>
                  </div>

                  {state[childCategory.id] && (
                    <div className="flex flex-col">
                      {childCategory.children?.map((item) => (
                        <Link
                          key={item.id}
                          to={`/product-category/${item.slug}`}
                          className="flex items-center gap-2 py-2 border-b px-6"
                        >
                          <img src={cr} className="w-6 h-6" alt="" />
                          <p>{item.name}</p>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

    </div>
  );
};

export default AccordionCustom;