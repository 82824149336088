import React, { useEffect, useState } from "react";
import brand1 from "../../../assets/images/1.png";
import brand2 from "../../../assets/images/2.png";
import brand3 from "../../../assets/images/3.png";
import brand4 from "../../../assets/images/4.jpg";
import brand5 from "../../../assets/images/5.jpg";
import brand6 from "../../../assets/images/6.jpg";
import brand7 from "../../../assets/images/7.jpg";
import brand8 from "../../../assets/images/8.jpg";
import brand9 from "../../../assets/images/9.jpg";
import brand10 from "../../../assets/images/10.jpg";
import { SERVER_URL } from "../../../constants/apiLinks";
const images = [
  {
    id: 1,
    imgSrc: brand1,
    link: "",
  },
  {
    id: 2,
    imgSrc: brand2,
    link: "",
  },
  {
    id: 3,
    imgSrc: brand3,
    link: "",
  },
  {
    id: 4,
    imgSrc: brand4,
    link: "",
  },
  {
    id: 5,
    imgSrc: brand5,
    link: "",
  },
  {
    id: 6,
    imgSrc: brand6,
    link: "",
  },
  {
    id: 7,
    imgSrc: brand7,
    link: "",
  },
  {
    id: 8,
    imgSrc: brand8,
    link: "",
  },
  {
    id: 9,
    imgSrc: brand9,
    link: "",
  },
  {
    id: 10,
    imgSrc: brand10,
    link: "",
  },
];
function BrandPartner() {
  const [brandImage,setBrandImage] = useState<any>([]);
  useEffect(() => {
    // Fetch privacy policy content
    const fetchContent = async () => {
      try {
        const otherResponse = await fetch(`${SERVER_URL}/api/user/other/partnersImage`);
        const otherData = await otherResponse.json();
        setBrandImage(otherData.data);
      } catch (error) {
        console.error("Error fetching the data:", error);
      }
    };

    fetchContent();
  }, []);
  return (
    <>
      {" "}
      {/* Brands partner start */}
      <section className="flex flex-col mt-10 gap-10 py-16 bg-[#F7F7F7]">
        <div className="flex gap-4 flex-col px-4 h-full relative  z-0  md:w-[85%] w-[95%] mx-auto">
          <div className="flex items-center gap-4 z-0 relative">
            <h2 className="font-poppins text-[24px] z-[-1] shrink-0 font-semibold">
              Partners & Brands
            </h2>
            <div className="bg-[#D5D5D5] h-[2px] rounded-full w-full z-0"></div>
          </div>
          {brandImage?(
          <div
            id="scroll-container"
            className="flex flex-1 overflow-x-scroll no-scrollbar hover:animate-none relative flex-col gap-2 scroll-linear z-0"
          >
            <ul className="px-4 flex gap-2">
              {brandImage.map((index:any) => {
                return (
                  <li key={index._id} className="">
                    <img
                      src={index.image}
                      className="bg-app_white min-w-[300px] h-[300px] shrink-0"
                      alt=""
                    />
                  </li>
                );
              })}
            </ul>
          </div>
          ):"Loading..."}
        </div>
      </section>
      {/* Brands partner End */}
    </>
  );
}

export default BrandPartner;
