import { useNavigate } from "react-router-dom";
import NavBar from "../../component/NavBar/NavBar";
import NavbarShops from "../../component/navBarShop/navBar";
import Footer from "../../component/Footer/Footer";
import Select from "react-dropdown-select";
import { useState,useEffect } from "react";
import { Checkbox } from "../../component/ui/check-box";

import {
  CitySelect,
  CountrySelect,
  StateSelect,

  //@ts-ignore
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { SERVER_URL } from "../../constants/apiLinks";

interface Option {
  id: number;
  name: string;
}
function CheckeOut() {
  const navigate = useNavigate();
  const [shipToDifferentAddress, setShipToDifferentAddress] = useState(false);
  const [selectedValue, setSelectedValue] = useState<Option | null>(null); // For single select
  const [showStripe,setShowStripe] = useState(null);
  const [loading,setLoading] = useState(false);
  const handleCheckboxChange = () => {
    setShipToDifferentAddress(!shipToDifferentAddress);
    console.log(shipToDifferentAddress);
  };
  const options = [
    {
      id: 1,
      name: "Canada",
    },
    {
      id: 2,
      name: "United States(US)",
    },
  ];

  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);

  // Flowbite End
//Aq Developer code
const fetchAllData = async () => {
  setLoading(true);
  try {
    const fetchData = async (endpoint:string) => {
      const response = await fetch(`${SERVER_URL}/api${endpoint}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      return response.json();
    };

    // Fetch all data and update respective states
    const responses = await Promise.all([
      fetchData("/layout1/checkOutPageStripeHideShow").then((data) => setShowStripe(data?.data?.[0].status)),
    ]);

    console.log("All data fetched successfully:", responses);
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false); // Stop loading
  }
};

useEffect(() => {
  fetchAllData();
}, []);
//Aq developer code end
  
  return (
    <>
      {/* Nvbar section start */}
      <NavBar
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />
      {/* Nvbar section End */}
      {/* <div className="pt-[85px]">
        <NavbarShops />
      </div> */}
      <div className="h-24"></div>
      <section className="flex flex-col gap-4 items-center h-full relative justify-center  z-0 md:w-[85%] w-[95%] mx-auto">
        <div className="flex flex-col lg:flex-row w-full max-w-screen-lg mx-auto  my-3 xl:my-20 gap-8">
          <div className="flex-1 shrink-0">
            <div className="flex flex-col gap-2 pb-6">
              <span className="uppercase text-app_text font-bold">
                Billing Details
              </span>
              <div className="w-full h-[1px] bg-gray-300"></div>
            </div>
            {/* Billing start */}
            <form action="" className="flex flex-col gap-4">
              <div className="flex flex-col min-[500px]:flex-row gap-4 w-full">
                <div className="flex flex-col w-full">
                  <label className="block text-app_text text-[14px] mb-2">
                    First name <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                    id="email"
                    type="email"
                    placeholder="Email address"
                  />
                  <p className="text-red-500 text-xs italic mt-2">
                    {/* Invalid email */}
                  </p>
                </div>
                <div className="flex flex-col w-full">
                  <label className="block text-app_text text-[14px] mb-2">
                    Last name <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                    id="email"
                    type="email"
                    placeholder="Email address"
                  />
                  <p className="text-red-500 text-xs italic mt-2">
                    {/* Invalid email */}
                  </p>
                </div>
              </div>
              <div className="flex flex-col">
                <label className="block text-app_text text-[14px] mb-2">
                  Company name (optional)
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                  id="email"
                  type="email"
                  placeholder=""
                />
                <p className="text-red-500 text-xs italic mt-2">
                  {/* Invalid email */}
                </p>
              </div>
              <div className="flex flex-col text-app_text">
                <label className="block text-app_text text-[14px] mb-2">
                  Country / Region <span className="text-red-500">*</span>
                </label>
                <Select
                  options={options}
                  labelField="name"
                  valueField="id"
                  values={selectedValue ? [selectedValue] : []} // Pass the selected value(s)
                  onChange={(value) => setSelectedValue(value[0] || null)} // Update the selected value(s)
                />
                <p className="text-red-500 text-xs italic mt-2">
                  {/* Invalid email */}
                </p>
              </div>
              <div className="flex flex-col ">
                <label className="block text-app_text text-[14px] mb-2">
                  Street address <span className="text-red-500">*</span>
                </label>
                <div className="flex gap-3 flex-col">
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                    id="email"
                    type="email"
                    placeholder="House number and street name"
                  />
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                    id="email"
                    type="email"
                    placeholder="Apartment, suite, unit, etc. (optional)"
                  />
                </div>
                <p className="text-red-500 text-xs italic mt-2">
                  {/* Invalid email */}
                </p>
              </div>
              <div className="flex flex-col">
                <label className="block text-app_text text-[14px] mb-2">
                  Town / City <span className="text-red-500">*</span>
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                  id="email"
                  type="email"
                  placeholder=""
                />
                <p className="text-red-500 text-xs italic mt-2">
                  {/* Invalid email */}
                </p>
              </div>
              <div className="flex flex-col text-app_text">
                <h6>Country</h6>
                <CountrySelect
                  priority={["US", "CA", "MX"]}
                  onChange={(e: any) => {
                    setCountryid(e.id);
                  }}
                  placeHolder="Select Country"
                />
                <h6>State</h6>
                <StateSelect
                  countryid={countryid}
                  onChange={(e: any) => {
                    setstateid(e.id);
                  }}
                  placeHolder="Select State"
                />
                <h6>City</h6>
                <CitySelect
                  countryid={countryid}
                  stateid={stateid}
                  onChange={(e: any) => {
                    console.log(e);
                  }}
                  placeHolder="Select City"
                />
              </div>{" "}
              <div className="flex flex-col">
                <label className="block text-app_text text-[14px] mb-2">
                  Postcode / ZIP *
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                  id="email"
                  type="email"
                  placeholder=""
                />
                <p className="text-red-500 text-xs italic mt-2">
                  {/* Invalid email */}
                </p>
              </div>
              <div className="flex flex-col">
                <label className="block text-app_text text-[14px] mb-2">
                  Phone *
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                  id="email"
                  type="email"
                  placeholder=""
                />
                <p className="text-red-500 text-xs italic mt-2">
                  {/* Invalid email */}
                </p>
              </div>
              <div className="flex flex-col">
                <label className="block text-app_text text-[14px] mb-2">
                  Email address *
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                  id="email"
                  type="email"
                  placeholder=""
                />
                <p className="text-red-500 text-xs italic mt-2">
                  {/* Invalid email */}
                </p>
              </div>
            </form>
            {/* Billing End */}

            {/* Create start */}
            <div className="flex items-center space-x-2 mt-5">
              <Checkbox id="Account" />
              <label
                htmlFor="Account"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Create an account?
              </label>
            </div>
            {/* Create end */}

            {/* Shipping address true start */}
            <div className="flex flex-col gap-2 pb-6 mt-10">
              <div className="flex items-center w-full justify-between space-x-2 mt-5">
                <label
                  htmlFor="differntAddress"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed uppercase text-app_text peer-disabled:opacity-70"
                >
                  Ship to a different address?
                </label>
                <Checkbox
                  id="differntAddress"
                  onClick={handleCheckboxChange}
                  checked={shipToDifferentAddress}
                />
              </div>
              <div className="w-full h-[1px] bg-gray-300 my-3"></div>
            </div>
            {/* Shipping address flase End */}

            <div className="flex flex-col">
              <label className="block text-app_text text-[14px] mb-2">
                Order notes (optional)
              </label>
              <textarea
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                id="email"
                rows={6}
                placeholder="Notes about your order, e.g. special notes for delivery."
              />
              <p className="text-red-500 text-xs italic mt-2">
                {/* Invalid email */}
              </p>
            </div>
            {/* Shipping address start */}
            {shipToDifferentAddress && shipToDifferentAddress ? (
              <form action="" className="flex flex-col gap-4 mt-6">
                <div className="flex gap-4 ">
                  <div className="flex flex-col w-full">
                    <label className="block text-app_text text-[14px] mb-2">
                      First name <span className="text-red-500">*</span>
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                      id="email"
                      type="email"
                      placeholder="Email address"
                    />
                    <p className="text-red-500 text-xs italic mt-2">
                      {/* Invalid email */}
                    </p>
                  </div>
                  <div className="flex flex-col w-full">
                    <label className="block text-app_text text-[14px] mb-2">
                      Last name <span className="text-red-500">*</span>
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                      id="email"
                      type="email"
                      placeholder="Email address"
                    />
                    <p className="text-red-500 text-xs italic mt-2">
                      {/* Invalid email */}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col">
                  <label className="block text-app_text text-[14px] mb-2">
                    Company name (optional)
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                    id="email"
                    type="email"
                    placeholder=""
                  />
                  <p className="text-red-500 text-xs italic mt-2">
                    {/* Invalid email */}
                  </p>
                </div>
                <div className="flex flex-col ">
                  <label className="block text-app_text text-[14px] mb-2">
                    Street address <span className="text-red-500">*</span>
                  </label>
                  <div className="flex gap-3 flex-col">
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                      id="email"
                      type="email"
                      placeholder="House number and street name"
                    />
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                      id="email"
                      type="email"
                      placeholder="Apartment, suite, unit, etc. (optional)"
                    />
                  </div>
                  <p className="text-red-500 text-xs italic mt-2">
                    {/* Invalid email */}
                  </p>
                </div>
                <div className="flex flex-col text-app_text">
                  <h6>Country</h6>
                  <CountrySelect
                    priority={["US", "CA", "MX"]}
                    onChange={(e: any) => {
                      setCountryid(e.id);
                    }}
                    placeHolder="Select Country"
                  />
                  <h6>State</h6>
                  <StateSelect
                    countryid={countryid}
                    onChange={(e: any) => {
                      setstateid(e.id);
                    }}
                    placeHolder="Select State"
                  />
                  <h6>City</h6>
                  <CitySelect
                    countryid={countryid}
                    stateid={stateid}
                    onChange={(e: any) => {
                      console.log(e);
                    }}
                    placeHolder="Select City"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="block text-app_text text-[14px] mb-2">
                    Postcode / ZIP <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                    id="email"
                    type="email"
                    placeholder=""
                  />
                  <p className="text-red-500 text-xs italic mt-2">
                    {/* Invalid email */}
                  </p>
                </div>
                <div className="flex flex-col">
                  <label className="block text-app_text text-[14px] mb-2">
                    Phone <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                    id="email"
                    type="email"
                    placeholder=""
                  />
                  <p className="text-red-500 text-xs italic mt-2">
                    {/* Invalid email */}
                  </p>
                </div>
                <div className="flex flex-col">
                  <label className="block text-app_text text-[14px] mb-2">
                    Email address <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                    id="email"
                    type="email"
                    placeholder=""
                  />
                  <p className="text-red-500 text-xs italic mt-2">
                    {/* Invalid email */}
                  </p>
                </div>
              </form>
            ) : null}
            {/* Shipping address End */}
          </div>

          {/* Invoice start */}
          <div className="flex-1 flex flex-col gap-3 shrink-0">
            <div className="flex flex-col gap-2 pb-6">
              <span className="uppercase font-semibold">Your order</span>
              <div className="w-full h-[1px] bg-gray-300"></div>
            </div>
            <div className="flex flex-col">
              <div className="p-4 flex justify-between text-black bg-[#f7f7f7]">
                <span className="uppercase font-semibold">Product</span>
                <span className="uppercase font-semibold">Subtotal</span>
              </div>
              <div className="p-4 flex items-start justify-between bg-[#fdfdfd]">
                <span className="uppercase text-[14px]">
                  Scotch Quick-drying Tacky Glue <span>× 1</span>
                </span>
                <span className="uppercase text-msky">$6.45</span>
              </div>
              <div className="flex items-start justify-between ">
                <div className="flex flex-col justify-between bg-[#f7f7f7] lg:flex-[2] flex-1 min-h-[300px] p-4 h-full">
                  <span className="uppercase text-[14px]">Subtotal</span>
                  <span className="uppercase text-[14px]">Shipping</span>
                  <span className="uppercase text-[14px]">Tax</span>
                  <span className="uppercase text-[14px]">Total</span>
                </div>
                <div className="flex flex-col justify-between flex-[1]  bg-white  min-h-[300px] p-4 h-full items-end">
                  <span className="uppercase text-[14px] text-msky">$6.45</span>
                  <span className="uppercase text-[14px] shrink-0">
                    <span className="font-bold text-app_secondary">
                      Flat rate:
                    </span>{" "}
                    $10.00
                  </span>
                  <span className="uppercase text-[14px] text-msky">$0.00</span>
                  <span className="uppercase text-[14px] text-msky">
                    $16.45
                  </span>
                </div>
              </div>
            </div>
              <section className="">
              {showStripe?(
                <>
                {/* ///////////////////Check Str */}
              <div className="p-4 flex cursor-pointer items-center gap-2 bg-[#f7f7f7]">
                <div className="ring-2 rounded-full">
                  <div className="rounded-full w-1 h-1 bg-msky m-1 ring-1"></div>
                </div>
                <span className="uppercase font-semibold">
                  Credit Card (Stripe)
                </span>
              </div>
              <form action="" className="">
                <div className="px-4">
                  <span className="text-[14px] py-2  bg">
                    Pay with your credit card via Stripe secure.
                  </span>
                  <div className="flex First name w-full  flex-col py-2 ">
                    <label className="block text-app_text text-[14px] mb-2">
                      First name *
                    </label>
                    <input
                      className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                      id="email"
                      type="email"
                      placeholder="1234 1234 1234 1234"
                    />
                    <p className="text-red-500 text-xs italic mt-2">
                      {/* Invalid email */}
                    </p>
                  </div>
                  <div className="flex gap-4 ">
                    <div className="flex flex-col">
                      <label className="block text-app_text text-[14px] mb-2">
                        Expiry Date *
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                        id="email"
                        type="email"
                        placeholder="MM/YY"
                      />
                      <p className="text-red-500 text-xs italic mt-2">
                        {/* Invalid email */}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <label className="block text-app_text text-[14px] mb-2">
                        Card Code (CVC) *
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                        id="email"
                        type="email"
                        placeholder="CVC"
                      />
                      <p className="text-red-500 text-xs italic mt-2">
                        {/* Invalid email */}
                      </p>
                    </div>
                  </div>
                </div>
                <p className="mt-10 text-[14px]">
                  Your personal data will be used to process your order, support
                  your experience throughout this website, and for other
                  purposes described in our privacy policy.
                </p>
                <div className="flex items-center space-x-2 mt-5">
                  <Checkbox id="terms" />
                  <label
                    htmlFor="terms"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    I have read and agree to the website terms and conditions *
                  </label>
                </div>
                <button className="py-3 px-2 mt-4 text-white bg-msky w-full  uppercase">
                  Place Order
                </button>
              </form>
            {/* ///////////////////Check Str end */}
              </>):( 
              <div className="flex flex-col gap-2">
                <button className="py-3 px-2 mt-4 text-white bg-msky w-full  uppercase">
                Contact info@bothut.com for processing the order
                </button>
            </div>
            )}
                
            </section>
          </div>
          {/* Invoice end */}
        </div>
      </section>
      {/* Footer start */}
      <Footer
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />

      {/* Footer End */}
    </>
  );
}

export default CheckeOut;
