import React from "react";
import ApplyNow from "../../ApplyNow/ApplyNow";

const Seven = ({sec8,content}) => {
  return (
    <>
     {sec8&&sec8.status?(
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${sec8&&sec8.image?sec8.image:""})`,
      }}
      className="  bg-fixed bg-cover text-white  py-20 px-[12%]"
    >
      <h1 className="font-mandhor text-[120px] text-[#6EC1E470]">{content&&content[0].title?content[0].title:""}</h1>

      <h2 className="text-white text-4xl md:text-5xl  lg:text-5xl  -mt-8">
      {sec8&&sec8.description?sec8.description:""}
      </h2>

      <p className="my-6">
        {content&&content[1].description?content[1].description:""}
      </p>

      <p className="my-6">
        {content&&content[2].description?content[2].description:""}

      </p>

      <p className="my-6">
        {content&&content[3].description?content[3].description:""}
      </p>

      <ApplyNow />
    </div>
    ):("")}
    </>
  );
};

export default Seven;
