import React from "react";
import ApplyNow from "../../ApplyNow/ApplyNow";

const Initial = ({sec1,content}) => {
  // console.log("data i got ",content);
  return (
    <>
    {sec1&&sec1.status?(
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-16  pb-20 px-[12%] ">
      <div className="bg-white   text-white  ">
        <h1 className="font-mandhor text-[120px] text-[#6EC1E470]">{content&&content[0].title?content[0].title:""}</h1>

        <h2 className=" text-4xl md:text-5xl  lg:text-5xl -mt-8 text-black ">
        {sec1&&sec1.description?sec1.description:""}
        </h2>

        <p className="my-6 text-msgray w-[90%] ">
        {content&&content[1].description?content[1].description:""}
        </p>

        <ApplyNow dark={true} />
      </div>
      <div className="flex items-center">
        <img
          style={{
            WebkitMaskImage: `url(${require("../../../assets/section/blob.png")})`,
            WebkitMaskSize: "contain",
            WebkitMaskAttachment: "unset",

            WebkitMaskRepeat: "no-repeat",
          }}
          src={sec1&&sec1.image?sec1.image:""}
          alt="SALES & COMMERCE"
        />
      </div>
    </div>
    ):("")}
    </>
  );
};

export default Initial;
