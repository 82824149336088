import React from "react";


const Forth = ({sec5,content}) => {
  const listData = [
    {
      id: 1,
      heading:`${content&&content[1].description?content[1].description:""}`
    },
    {
      id: 2,
      heading:`${content&&content[2].description?content[2].description:""}`
    },
    {
      id: 3,
      heading:`${content&&content[3].description?content[3].description:""}`
    },
    {
      id: 4,
      heading:`${content&&content[4].description?content[4].description:""}`
    },
  ];
  return (
    <>
     {sec5&&sec5.status?(
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-10  py-20 px-[12%]">
      <div className="bg-white text-white ">
        <h1 className="font-mandhor text-[120px] text-[#6EC1E470]">{content&&content[0].title?content[0].title:""}</h1>

        <h2 className="text-black text-4xl md:text-5xl  lg:text-5xl -mt-8">
        {sec5&&sec5.description?sec5.description:""}
        </h2>

        <ol className="text-black mt-16 list-disc px-4  ">
          {listData.map((item) => (
            <li className="mt-3   gap-4 items-center" key={item.id}>
              <p className=" text-msgray antialiased">{item.heading}</p>
            </li>
          ))}
        </ol>
      </div>

      <div className="flex items-center justify-center">
        <img
          style={{
            WebkitMaskImage: `url(${require("../../../assets/section/blob.png")})`,
            WebkitMaskSize: "contain",
            WebkitMaskAttachment: "unset",

            WebkitMaskRepeat: "no-repeat",
          }}
          src={sec5&&sec5.image?sec5.image:""}
          alt="Supply Chain Management"
        />
      </div>
    </div>
    ):("")}
    </>
  );
};

export default Forth;
