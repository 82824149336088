import React from "react";
import ApplyNow from "../../ApplyNow/ApplyNow";

const Six = ({sec7,content}) => {
  const listData = [
    {
      id: 1,
      heading: (
        <p className="text-black">
          <b className="text-black">{content&&content[1].title?content[1].title:""}</b> Accelerate
          {content&&content[1].description?content[1].description:""}
        </p>
      ),
    },
    {
      id: 2,
      heading: (
        <p className="text-black">
          <b className="text-black">{content&&content[2].title?content[2].title:""}</b>
          {content&&content[2].description?content[2].description:""}
        </p>
      ),
    },
    {
      id: 3,
      heading: (
        <p className="text-black">
          {content&&content[3].description?content[3].description:""}
        </p>
      ),
    },
  ];
  return (
    <>
     {sec7&&sec7.status?(
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 py-20 px-[12%]">
      <div className="bg-white text-white ">
        <h1 className="font-mandhor text-[120px] text-[#6EC1E470]">{content&&content[0].title?content[0].title:""}</h1>

        <h2 className="text-black text-4xl md:text-5xl  lg:text-5xl -mt-8 ">
          {sec7&&sec7.description?sec7.description:""}
        </h2>

        <ol className="text-black mt-16  mb-6    ">
          {listData.map((item) => (
            <li className="mt-3   gap-4 items-center" key={item.id}>
              <p className=" text-msgray antialiased">{item.heading}</p>
            </li>
          ))}
        </ol>
        <ApplyNow dark={true} />
      </div>

      <div className="flex items-center justify-center">
        <img
          className="w-[100%] lg:w-[80%] md:w-[90%]"
          src={sec7&&sec7.image?sec7.image:""}
          alt="Application Services"
        />
      </div>
    </div>
    ):("")}
    </>
  );
};

export default Six;
