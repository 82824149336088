import React from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";


const Second = ({sec3,content}) => {
  const listData = [
    {
      id: 1,
      heading: `${content&&content[3].description?content[3].description:""}`,
    },
    {
      id: 2,
      heading: `${content&&content[4].description?content[4].description:""}`,
    },
    {
      id: 3,
      heading: `${content&&content[5].description?content[5].description:""}`,
    },
  ];
  return (
    <>
    {sec3&&sec3.status?(
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-10  py-20 px-[12%] ">
      <div className="bg-white text-white ">
        <h1 className="font-mandhor text-[120px] text-[#6EC1E470]">{content&&content[0].title?content[0].title:""}</h1>

        <h2 className="text-black text-4xl md:text-5xl  lg:text-5xl  -mt-8">
        {sec3&&sec3.description?sec3.description:""}
        </h2>

        <p className="my-6 text-msgray text-justify">
          {content&&content[1].description?content[1].description:""}
        </p>

        <p className="mt-6 mb-4 text-msky text-4xl font-semibold">
        {content&&content[2].title?content[2].title:""}
        </p>

        <ul className="text-black">
          {listData.map((item) => (
            <li
              className="mb-3 text-[20px] flex gap-4 items-center uppercase"
              key={item.id}
            >
              <FaArrowAltCircleRight className="text-msky" />
              <h2 className=" text-msgray antialiased">{item.heading}</h2>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex items-center">
        <img
          style={{
            WebkitMaskImage: `url(${require("../../../assets/section/blob.png")})`,
            WebkitMaskSize: "contain",
            WebkitMaskAttachment: "unset",

            WebkitMaskRepeat: "no-repeat",
          }}
          src={require("../../../assets/section/second.jpg")}
          alt="SALES & COMMERCE"
        />
      </div>
    </div>
     ):("")}
    </>
  );
};

export default Second;
