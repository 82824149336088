import React, { useState,useEffect } from "react";
import NavBar from "../component/NavBar/NavBar";
import Footer from "../component/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from "../constants/apiLinks";
import spinner from "../assets/svg/spinner.svg";

const fetchData = async () => {
  try {
    const responseHeroHB = await fetch(`${SERVER_URL}/api/layout1/FITHeroHB`);
    const dataHeroHB = await responseHeroHB.json();

    const responseHeroHD = await fetch(`${SERVER_URL}/api/layout1/FITHeroHD`);
    const dataHeroHD = await responseHeroHD.json();

    const responseSec2 = await fetch(`${SERVER_URL}/api/layout1/FITSec2`);
    const dataSec2 = await responseSec2.json();

    const responseSec3HB = await fetch(`${SERVER_URL}/api/layout1/FITSec3HB`);
    const dataSec3HB = await responseSec3HB.json();

    const responseSec3I1 = await fetch(`${SERVER_URL}/api/layout1/FITSec3I1`);
    const dataSec3I1 = await responseSec3I1.json();

    const responseSec3I2 = await fetch(`${SERVER_URL}/api/layout1/FITSec3I2`);
    const dataSec3I2 = await responseSec3I2.json();

    const responseSec3I3 = await fetch(`${SERVER_URL}/api/layout1/FITSec3I3`);
    const dataSec3I3 = await responseSec3I3.json();

    const responseSec3I4 = await fetch(`${SERVER_URL}/api/layout1/FITSec3I4`);
    const dataSec3I4 = await responseSec3I4.json();

    const responseSec4HB = await fetch(`${SERVER_URL}/api/layout1/FITSec4HB`);
    const dataSec4HB = await responseSec4HB.json();

    const responseSec4I1 = await fetch(`${SERVER_URL}/api/layout1/FITSec4I1`);
    const dataSec4I1 = await responseSec4I1.json();

    const responseSec4I2 = await fetch(`${SERVER_URL}/api/layout1/FITSec4I2`);
    const dataSec4I2 = await responseSec4I2.json();

    const responseSec4I3 = await fetch(`${SERVER_URL}/api/layout1/FITSec4I3`);
    const dataSec4I3 = await responseSec4I3.json();

    const responseSec4I4 = await fetch(`${SERVER_URL}/api/layout1/FITSec4I4`);
    const dataSec4I4 = await responseSec4I4.json();

    const responseVendorP = await fetch(`${SERVER_URL}/api/layout2/vendorProfile`);
    const dataVendorP = await responseVendorP.json();

    const responseCertTitle = await fetch(`${SERVER_URL}/api/layout1/Certifications`);
    const dataCertTitle = await responseCertTitle.json();

    const responseCertImg = await fetch(`${SERVER_URL}/api/user/other/certImage`);
    const dataCertImg = await responseCertImg.json();

    return {
      dataHeroHB,
      dataHeroHD,
      dataSec2,
      dataSec3HB,
      dataSec3I1,
      dataSec3I2,
      dataSec3I3,
      dataSec3I4,
      dataSec4HB,
      dataSec4I1,
      dataSec4I2,
      dataSec4I3,
      dataSec4I4,
      dataVendorP,
      dataCertTitle,
      dataCertImg,
    };
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const FederalItSolutions = () => {
  const navigate = useNavigate();
  const [loading,setLoading] = useState(true);
  const [heroHB, setHeroHB] = useState(null);
  const [heroHD, setHeroHD] = useState(null);
  const [sec2, setSec2] = useState(null);
  const [sec3HB, setSec3HB] = useState(null);
  const [sec3I1, setSec3I1] = useState(null);
  const [sec3I2, setSec3I2] = useState(null);
  const [sec3I3, setSec3I3] = useState(null);
  const [sec3I4, setSec3I4] = useState(null);
  const [sec4HB, setSec4HB] = useState(null);
  const [sec4I1, setSec4I1] = useState(null);
  const [sec4I2, setSec4I2] = useState(null);
  const [sec4I3, setSec4I3] = useState(null);
  const [sec4I4, setSec4I4] = useState(null);
  const [vendorP, setVendorP] = useState(null);
  const [certTitle, setCertTitle] = useState(null);
  const [certImg, setCertImg] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  useEffect(() => {
    setLoading(true);
    fetchData().then((data) => {
      if (data) {
        setHeroHB(data.dataHeroHB.data[0]);
        setHeroHB(data.dataHeroHB.data[0]);
        setHeroHD(data.dataHeroHD.data[0]);
        setSec2(data.dataSec2.data[0]);
        setSec3HB(data.dataSec3HB.data[0]);
        setSec3I1(data.dataSec3I1.data[0]);
        setSec3I2(data.dataSec3I2.data[0]);
        setSec3I3(data.dataSec3I3.data[0]);
        setSec3I4(data.dataSec3I4.data[0]);
        setSec4HB(data.dataSec4HB.data[0]);
        setSec4I1(data.dataSec4I1.data[0]);
        setSec4I2(data.dataSec4I2.data[0]);
        setSec4I3(data.dataSec4I3.data[0]);
        setSec4I4(data.dataSec4I4.data[0]);
        setVendorP(data.dataVendorP.data[0]);
        setCertTitle(data.dataCertTitle.data[0]);
        setCertImg(data.dataCertImg.data[0]);
        setCertImg(data.dataCertImg);
      }
      setLoading(false);
    });
  }, []);
  // console.log("cert",JSON.stringify(certTitle,null,2));
  return (
    <>
    {loading?<div className="flex flex-col h-[50vh] items-center justify-center">
        <img src={spinner} className="h-24 w-44" alt="Loading" />
      </div>:(
    <div>
      <NavBar
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />
      {/* banner image with text */}
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url(${heroHB.status?heroHB?.image:""})`,
        }}
        className="text-white py-32   bg-right bg-cover relative flex items-center"
      >
        <div className="px-6 6 md:px-[12%]">
          <p className="text-xl mb-4">{heroHB.status?heroHB?.title:""}</p>
          <h1 className="text-4xl font-semibold ">
            {heroHD.status?heroHD?.title:""}
          </h1>
          <br />
          <p className=" md:w-[60%]">
          {heroHD.status?heroHD?.description:""}
          </p>
        </div>
      </div>
      {/* digital gvt solutions */}
      {/* <div className="px-6 md:px-[12%] my-10">
        <h1 className="text-center font-semibold text-2xl mb-4">
          Digital Government Solutions
        </h1>
        <p className="text-base mb-3 text-msgray">
          Government agencies are modernizing to keep pace with the changing
          needs of the evolving technological landscape. Modernization is
          crucial to stay ahead of security threats, increasing data integrity
          and evolving government digital strategies. It is a journey that faces
          budgetary, legacy systems, and policy constraints. The right partners,
          solutions, and strategic planning ensure that your organization
          achieves a user-friendly IT system that is secure, adaptable, and
          cost-effective.
        </p>
        <p className="text-base text-msgray">
          CDW·G can provide you with the right strategy for mission success.
        </p>
      </div> */}
      {/* sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-24 py-5 px-[12%] items-center">
        <div>
          <h1 className="text-3xl my-4">{sec2.status?sec2?.title:""}</h1>
          <p className="text-msgray">
          {sec2.status?sec2?.description:""}
          </p>
        </div>
        {sec2.status?(
          <img
            alt="aboutus"
            src={sec2?.image}
            className=" bg-contain rounded-lg"
          />
        ):""}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-24 py-5 px-[12%] items-center">
        {sec3HB.status?(
        <img
          alt="aboutus"
          src={sec3HB?.image}
          className=" h-auto bg-cover rounded-lg"
        />
        ):""}
        <div className="">
          <h1 className="text-3xl my-4">{sec3HB.status?sec3HB?.title:""}</h1>
          <ol className="text-msgray list-disc ">
            <li>
            {sec3I1.status?sec3I1?.description:""}
            </li>
            <li>
              {sec3I2.status?sec3I2?.description:""}
            </li>
            <li>
              {sec3I3.status?sec3I3?.description:""}
            </li>
            <li>
              {sec3I4.status?sec3I4?.description:""}
            </li>
          </ol>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-24 py-5 px-[12%] items-center">
        <div>
          <h1 className="text-3xl my-4">{sec4HB.status?sec4HB?.title:""}</h1>
          <ol className="text-msgray list-disc">
            <li>
              {sec4I1.status?sec4I1?.description:""}
            </li>
            <li>
              {sec4I2.status?sec4I2?.description:""}

            </li>
            <li>
              {sec4I3.status?sec4I3?.description:""}

            </li>
            <li>
              {sec4I4.status?sec4I4?.description:""}

            </li>
          </ol>
        </div>
        {sec4HB.status?(
        <img
          alt="aboutus"
          src={sec4HB?.image}
          className="w-full h-auto bg-cover rounded-lg"
        />
        ):""}
      </div>
      {/* vendor profile & certifications */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-14 py-5 px-[12%]">
        {vendorP.status?(
        <div >
          <h1 className="text-3xl my-4">{vendorP.data[0].title}</h1>
      {vendorP.data.slice(1,vendorP.data.length).map((item)=>(
             <div key={item._id} className="text-msgray space-y-3">
               <p>
                 <span className="font-bold">{item.title}: </span>{item.description}
               </p>
               
             </div>
            ))}
           </div>
        ):""}
{certTitle.status?(
        <div>
          <h1 className="text-3xl my-4">{certTitle?.title}</h1>
          <div className="flex md:flex-col lg:flex-row flex-col items-center">
            {certImg.data.map((item)=>(
              <img
              key={item._id}
              alt="certificates"
              src={item.image}
              className="w-60 h-30  rounded-lg"
            />
            ))}
          </div>
        </div>
):""}
      </div>

      <Footer
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />
    </div>
    )}
    </>
  );
};

export default FederalItSolutions;
