import React from "react";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";


const IT = ({heading,Card1,Card2,Card3,lastText,lastSection}) => {
  console.log("card",JSON.stringify(lastSection,null,2));
  const data = [
    {
     id:1,
      img: `${Card1 && Card1.image ? Card1.image : ""}`,
      icon: `${Card1 && Card1.logo ? Card1.logo : ""}`,
      heading: `${Card1 && Card1.title ? Card1.title : ""}`,
      subheading: `${Card1 && Card1.subheading ? Card1.subheading : ""}`,
      details: `${Card1 && Card1.description ? Card1.description : ""}`,
      status: `${Card1 && Card1.status ? Card1.status : ""}`,
    },
    {
      id:2,
      img: `${Card2 && Card2.image ? Card2.image : ""}`,
      icon: `${Card2 && Card2.logo ? Card2.logo : ""}`,
      heading: `${Card2 && Card2.title ? Card2.title : ""}`,
      subheading: `${Card2 && Card2.subheading ? Card2.subheading : ""}`,
      details: `${Card2 && Card2.description ? Card2.description : ""}`,
      status: `${Card2 && Card2.status ? Card2.status : ""}`,
    },
    {
      id:3,
      img: `${Card3 && Card3.image ? Card3.image : ""}`,
      icon: `${Card3 && Card3.logo ? Card3.logo : ""}`,
      heading: `${Card3 && Card3.title ? Card3.title : ""}`,
      subheading: `${Card3 && Card3.subheading ? Card3.subheading : ""}`,
      details: `${Card3 && Card3.description ? Card3.description : ""}`,
      status: `${Card3 && Card3.status ? Card3.status : ""}`,
    },
  ];
  const navigate = useNavigate();
  return (
    <div className="mb-24 " id="it">
      <h1 className="text-5xl  font-semibold  text-center my-24 ">
        {heading&&heading.title}
      </h1>
      <div className="flex flex-col lg:flex-row   px-[12%] gap-10">
        {data.map((item) => (
          <>
          {item.status?(
            <div key={item.id} className="shadow-lg">
            <img alt={item.heading} src={item.img} />
            <div className=" p-7 bg-[#54595F] text-white flex gap-4 items-center">
              <div>
                <span className="text-white text-4xl">
                  {" "}
                  <img
                    alt={item.heading}
                    className="w-14  "
                    style={{
                      filter:
                        " invert(0.5) sepia(1) saturate(1) hue-rotate(175deg) brightness(10)",
                    }}
                    src={item.icon}
                  />
                </span>
              </div>
              <div className="flex flex-col gap-2 ">
                <h1 className="text-2xl ">{item.heading}</h1>
                <h1>{item.subheading}</h1>
              </div>
            </div>
            <div className="bg-white text-mgray pt-10 pb-16 p-7 ">
              {item.details}
            </div>
          </div>
          ):("")}
          
          </>
        ))}
      </div>

      <p className=" flex flex-col md:flex-row  lg:flex-row text-lg items-center justify-center mt-24 px-6 text-center">
        {lastText&&lastText.title?lastText.title:""}

        <span
          onClick={() => navigate("/contact-us")}
          className="text-msky  cursor-pointer flex items-center text-1xl ml-3"
        >
          Get Started <IoIosArrowRoundForward className="text-3xl" />
        </span>
      </p>
{lastSection && lastSection.status?(
    <div
    style={{
      backgroundSize: "100%",
      backgroundAttachment: "fixed",
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${lastSection&&lastSection.image?lastSection.image:""})`,
    }}
    className="py-28 px-[12%] mt-24 relative text-center flex items-center justify-center "
  >
    <h1
      onClick={() => navigate("/contact-us")}
      className="hover:text-msky text-4xl max-w-screen-md cursor-pointer text-white absolute z-10 mx-4  "
    >
     {lastSection&&lastSection.title?lastSection.title:""}
    </h1>
    <div className="absolute top-0 left-0 w-full h-full bg-black opacity-70" />
  </div>
):("")}
  
    </div>
  );
};

export default IT;
