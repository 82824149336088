import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../component/NavBar/NavBar";
import NavbarShops from "../../component/navBarShop/navBar";
import Footer from "../../component/Footer/Footer";
import cross from "../../assets/svg/trash.svg";
import prodImg from "../../assets/images/3.png";
// import { products } from "../../components/product";

const Cart = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen flex flex-col w-full ">
      <NavBar
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />
      {/* Nvbar section End */}
      <div className="pt-[85px]">
        <NavbarShops />
      </div>
      {/* Product start */}

        <div className="min-h-[500px] no-scrollbar bg-gray-50/70 flex-col   overflow-y-scroll  relative z-0 mx-auto flex  w-[95%] items-start   font-poppins md:w-[85%] p-10 mb-10">
          <table class="min-w-full  border">
            <thead class="border-b">
              <tr className="">
                <th
                  scope="col"
                  class="text-base  font-medium text-gray-900 px-6 py-4 text-left"
                >
                  Action
                </th>
                <th
                  scope="col"
                  class="text-base font-medium text-gray-900 px-6 py-4 text-left"
                >
                  Product
                </th>
                <th
                  scope="col"
                  class="text-base font-medium text-gray-900 px-6 py-4 text-left"
                >
                  Price
                </th>
                <th
                  scope="col"
                  class="text-base font-medium text-gray-900 px-6 py-4 text-left"
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  class="text-base uppercase font-medium text-gray-900 px-6 py-4 text-left"
                >
                  subtotal
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-b ">
                <td class="px-6 py-4  text-sm font-medium text-gray-900">
                  <button>
                    <img src={cross} className="h-7 w-7" alt="delete" />
                  </button>
                </td>
                <td class="text-sm lg:items-center flex lg:flex-row  flex-col gap-2 text-gray-900  px-6 py-4 ">
                  <img src={prodImg} className="h-16 w-16" alt="delete" />
                  <span className="">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Natu
                  </span>
                </td>
                <td class="text-sm whitespace-nowrap  shrink-0 text-gray-900 font-light px-6 py-4 ">
                  75.65 $
                </td>
                <td class="text-sm basis-full  h-full min-h-full   text-gray-900 font-light px-6 ">
                  <div className="flex ">
                    <button className="text-[18px] px-4 py-2 border">+</button>
                    <button className="text-[18px] px-4 py-2 border">1</button>
                    <button className="text-[18px] px-4 py-2 border">-</button>
                  </div>
                </td>
                <td class="text-sm text-gray-900 font-light px-6 py-4 ">
                  75.65 $
                </td>
              </tr>
            </tbody>
          </table>
          <div className="flex justify-between border w-full p-3 ">
            <div className="flex gap-2 flex-col lg:flex-row">
              <input type="text" placeholder="Coupon code" name="" id="" />
              <button className="py-2 px-2 text-white shrink-0 bg-msky ">
                Apply Coupon
              </button>
            </div>
          </div>
          <div className=" text-black flex font-semibold justify-end items-end w-full mt-5">
            {/* Cart Info start */}
            <div className="flex flex-col max-w-[400px] w-full border items-center  pb-4 mb-4">
              <span className="py-2 bg-app_bg_gray w-full text-center uppercase">
                Cart TOTal
              </span>
              <div className="flex flex-col p-2 w-full">
                <div className="flex justify-between w-full">
                  <div className="flex-1 border-r border-b p-2">Subtotal</div>
                  <div className="flex-[2] border-b p-2 text-msky">$177.52</div>
                </div>
                <div className="flex justify-between w-full">
                  <span className="flex-1 border-r border-b p-2">Shipping</span>
                  <div className="flex-[2] border-b p-2 gap-3">
                    {/* <span>Free Shipping on order above USD150.00</span> */}
                    <span>
                      <span className="font-normal"> &nbsp;Shipping to</span>
                      NY.
                    </span>
                    <Link
                      to={""}
                      className="flex flex-col underline underline-offset-8 font-bold"
                    >
                      Change address
                    </Link>
                  </div>
                </div>
                <div className="flex justify-between w-full">
                  <div className="flex-1 border-r border-b p-2 ">Tax</div>
                  <div className="flex-[2] border-b p-2 text-msky font-normal">
                    $0.00
                  </div>
                </div>
                <div className="flex justify-between w-full">
                  <div className="flex-1 border-r border-b p-2 ">Total</div>
                  <div className="flex-[2] border-b p-2 text-msky">
                    {" "}
                    $177.52
                  </div>
                </div>
                <Link
                  to={"/checkout"}
                  className="py-3 text-center px-2 text-white bg-msky w-full mt-4 text-ap"
                >
                  Proceed
                </Link>
              </div>
            </div>
            {/* Cart Info End */}
          </div>
        </div>


      {/* Footer start */}
      <div className="mt-auto ">
        <Footer
          onAboutPress={() => navigate("/?section=aboutus")}
          onServicePress={() => navigate("/?section=service")}
        />
      </div>

      {/* Footer End */}
    </div>
  );
};

export default Cart;
