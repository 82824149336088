import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import BrandPartner from "../../component/common/brandPartner";
import NavBar from "../../component/NavBar/NavBar";
import Footer from "../../component/Footer/Footer";
import ProductImageGallery from "../../component/ProductImageGallery/index"; // Import the image gallery
import spinner from "../../assets/svg/spinner.svg";
import favourite from "../../assets/svg/favH.svg";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../component/ui/tabs";
import SignUp from "../../component/signup/signUp";
import Ratings from "../../component/rating/rating";
import { Checkbox } from "../../component/ui/check-box";
import { SERVER_URL } from "../../constants/apiLinks";
import ScrollToTop from "../../component/scrollToTop/scroll";
import searchW from "../../assets/svg/mag-w.svg";
import AccordionCustom from "../../component/accordion/accordion";
import hamburgerb from "../../assets/svg/hamburgerb.svg";
import { Sheet, SheetContent, SheetTrigger } from "../../component/ui/sheet";
import NavbarShops from "../../component/navBarShop/navBar";

function Product() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState<any>(null); // Initialize as null
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>(''); // For search input
  const [mobSearch, setMobSearch] = useState<string>(''); 
  const fetchProduct = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/api/user/product/${id}`);
      const data = await response.json();
      setProduct(data.data);
      console.log(data.data);
      
    } catch (error) {
      console.error("Error fetching product:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) fetchProduct();
  }, [id]);

  const renderPrice = (priceText:any) => {
    const pricePattern = /(\$\d+\.\d+)\s+(\$\d+\.\d+)\s+\((.+?)\)/;
    const match = priceText.match(pricePattern);
  
    if (!match) {
      return<h1 className="text-[20px] text-app_primary sm:text-[24px] md:text-[36px]">
      {priceText}</h1>; // Handle invalid format
    }
  
    const [, originalPrice, salePrice, unit] = match; // Destructure the matched groups
  
    return (
      <h1 className="text-[20px] text-app_primary sm:text-[24px] md:text-[36px]">
        <span className="line-through text-sm text-gray-500 mr-2">{originalPrice}</span>
        <span className=" mr-2">{salePrice}</span>
        <span className="">({unit})</span>
      </h1>
    );
  }
  const renderShortDescription = (description: string) => {
    const descriptionItems = description.split("\n\n"); // Split on double newlines
    return (
      <ul className="flex gap-3 text-app_text text-[14px] flex-col mt-2 list-disc ml-4">
        {descriptionItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };

  if (loading)
    return (
      <div className="flex flex-col h-[50vh] items-center justify-center">
            <ScrollToTop/>
        <img src={spinner} className="h-24 w-44" alt="Loading" />
      </div>
    ); // Handle loading state
  if (!product) return <p>Product not found.</p>; // Handle product absence

  // Prepare images for the gallery
  const galleryImages = product.imageUrls?.map((url: string) => ({
    url, // Use the same URL for both full-size and thumbnail in this example
    thumbnailUrl: url, // Adjust this if you have separate thumbnails
  }));

  return (
    <>
      <NavBar
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />
    <div className="top-[70px] min-[400px]:top-[70px] min-[767px]:top-[73px] min-[768px]:top-[83px] min-[1440px]:top-[83px]  z-50 fixed w-full bg-white">
      <NavbarShops />
       {/* Mobile Menu start */}
<div className="p-4 min-[1440px]:hidden">
     <Sheet>
              <SheetTrigger asChild>
                <button className="min-[1440px]:hidden inline-flex gap-2 items-center font-bold text-black text-[14px] font-Noto ">
                  <img src={hamburgerb} className="h-5 w-5" alt="" />
                  Menu
                </button>
              </SheetTrigger>
              <SheetContent
                side={"left"}
                className="no-scrollbar flex h-full w-full flex-col gap-3 overflow-scroll bg-gray-200 max-w-[300px] px-0"
              >
                <div className="flex flex-col gap-2 pt-10">
                  <div className="px-4">
                    <div className="flex items-stretch gap-4 p-0 m-0 overflow-hidden rounded  bg-app_bg_gray border-none outline-none ">
                      <input
                        type="text"
                        className="w-full bg-transparent px-3 my-0 py-3 border-none outline-none focus:border-none"
                        placeholder="Search"
												onChange={(e)=>setMobSearch(e.target.value)}
                      />
                      <button className="flex items-center self-stretch flex-col w-20 justify-center   bg-msky h-auto">
                        <img src={searchW} className="h-7 w-7" alt="" 
												onClick={() => navigate(`/shop`)}
												/>
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col gap-4">
                    <AccordionCustom />
                  </div>
                </div>
              </SheetContent>
    </Sheet>
</div>
            {/* Mobile Menu End */}
    </div>
    <div className="bg-app_bg_gray bg-opacity-100 h-[90px] min-[400px]:h-[129px]  min-[1440px]:h-[150px]"></div>
      <section className="flex flex-col h-full justify-center z-10 md:w-[85%] w-[95%] mx-auto font-poppins px-4 pt-20 relative">
        <div className="flex max-w-screen-xl mx-auto flex-col gap-2 md:flex-row">
          {/* <div className="max-w-[295px] self-center md:self-start lg:self-stretch lg:w-full md:flex-[4] md:px-4 bg-red-500"> */}
          <div className="max-w-[295px] sm:max-w-[400px] md:max-w-[300px] md:w-auto self-center md:self-start lg:self-stretch lg:max-w-[700px]  lg:w-[512px] md:flex-[4] md:px-4 ">
            {/* Replace MagnifierImage with ProductImageGallery */}
            {galleryImages && <ProductImageGallery images={galleryImages} />}
          </div>
          <div className="md:flex-[7] px-4">
            <h2 className="text-xl sm:text-2xl font-semibold font-Noto dark:text-white mb-2">
              {product.productName}
            </h2>
            <div className="bg-gray-300 h-[1px] w-full my-4"></div>
            <h1 className="">
              {product.productPrice&&renderPrice(product.productPrice)}
            </h1>

            <div className="mb-4 font-Noto">
              {product.shortDescription &&
                renderShortDescription(product.shortDescription)}
            </div>
            <div className="bg-gray-300 h-[1px] w-full my-10"></div>

            <div className="flex gap-2">
              <div className="flex border items-center">
                <button className="px-4 py-2">-</button>
                <button className="px-5 py-2 border-x">1</button>
                <button className="px-4 py-2">+</button>
              </div>
              <button className="px-3 py-2 text-app_white bg-app_primary">
                Add to Cart
              </button>
            </div>
            <img
              src={favourite}
              className="w-5 h-5 my-4"
              alt="favourite icon"
            />
            <div className="bg-gray-300 h-[1px] w-full mb-4 mt-8"></div>
            <p className="">{product?.brand}</p>
          </div>
        </div>
      </section>

      <Tabs defaultValue="Description" className=" md:w-full mt-10">
        <TabsList className="flex overflow-x-auto md:overflow-hidden gap-4 m-0 p-0 bg-white border-y py-2">
          <TabsTrigger value="Description" className="ml-52 md:ml-0">Description</TabsTrigger>
          <TabsTrigger value="addInfo">Additional information</TabsTrigger>
          <TabsTrigger value="brand">Brand</TabsTrigger>
          <TabsTrigger value="Review">Review (0)</TabsTrigger>
        </TabsList>

        <TabsContent value="Description">
          <div className="flex flex-col gap-3 px-8">
            <span className="text-[18px]">Description</span>
            <div className="mt-6">
              {product.fullDescription &&
                renderShortDescription(product.fullDescription)}
            </div>
          </div>
        </TabsContent>

        <TabsContent value="addInfo">
          <div className="flex flex-col gap-3 px-8">
            <span className="text-[18px]">Additional information</span>
            <div className="mt-6 grid grid-cols-2 grid-rows-2 ">
              <p className="font-semibold border border-l-0 px-4 py-2 border-b-0">Weight</p>
              <p className="border border-r-0 border-b-0 px-4 py-2">
                {product.additionalInfo && product.additionalInfo.Weight}
              </p>
              <p className="font-semibold border border-l-0 px-4 py-2">Dimensions</p>
              <p className="border border-r-0 px-4 py-2">
                {product.additionalInfo && product.additionalInfo.Dimensions}
              </p>
            </div>
          </div>
        </TabsContent>

        <TabsContent value="brand">
          <div className="flex flex-col gap-2 px-8">
            <span className="text-[18px]">Brand</span>
            <div className=" ">
              <p className="font-semibold text-xl">{product.brand}</p>
            </div>
          </div>
        </TabsContent>

        <TabsContent value="Review">
          <div className="flex flex-col gap-2 px-8">
            <h1 className="text-[18px] my-6">Reviews</h1>
            <span>No reviews yet.</span>
          </div>
        </TabsContent>
      </Tabs>

      <BrandPartner />
      <SignUp />
      <Footer
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />
    </>
  );
}

export default Product;
