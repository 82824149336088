import React, { useEffect, useState } from "react";
import serviceList from "../../data/services.json";
const Services = ({
  onITProduct,
  onBusiness,
  onDigital,
  onTech,
  onSupply,
  onProcess,
  onApplication,
  onHigh,
  heading,
  sec1,
  sec2,
  sec3,
  sec4,
  sec5,
  sec6,
  sec7,
  sec8,
}) => {
  const [is, setIs] = useState(false);

  useEffect(() => {
    var header = document.getElementById("myHeader");
    const sticky = header.offsetTop;

    const changeNavBg = () => {
      if (window.scrollY + 30 >= sticky) {
        setIs(true);
      } else {
        setIs(false);
      }
    };
    window.addEventListener("scroll", changeNavBg);
    return () => {
      window.removeEventListener("scroll", changeNavBg);
    };
  }, []);

  return (
    <div id="myHeader" className="px-4  relative header">
      <h1 className="text-3xl my-4 text-msky ">{heading&&heading.title?heading.title:""}</h1>

      <ul
  className={`justify-center bg-msky p-6 mb-6 mt-12 hidden ${
    is ? "fixed" : "sticky"
  } lg:flex flex-wrap gap-2 top-9 left-0 w-full z-50 text-center`}
>
  <li
    onClick={onITProduct}
    className={` cursor-pointer text-white hover:antialiased`}
  >
    <span className="flex items-center">
      <h1 className="text-xs lg:text-sm divide-x-2 hover:text-msky hover:bg-white">
        {sec1&&sec1.status?sec1.title:""}
      </h1>
    </span>
  </li>
    <h1 className="mx-3 text-white">|</h1>

  <li
    onClick={onBusiness}
    className={` cursor-pointer text-white hover:antialiased`}
  >
    <span className="flex items-center">
      <h1 className="text-xs lg:text-sm hover:text-msky hover:bg-white">
        {sec2&&sec2.status?sec2.title:""}
      </h1>
    </span>
  </li>
  <h1 className="mx-3 text-white">|</h1>
  <li
    onClick={onDigital}
    className={` cursor-pointer text-white hover:antialiased`}
  >
    <span className="flex items-center">
      <h1 className="text-xs lg:text-sm hover:text-msky hover:bg-white">
        {sec3&&sec3.status?sec3.title:""}
      </h1>
    </span>
  </li>
  <h1 className="mx-3 text-white">|</h1>
  <li
    onClick={onTech}
    className={` cursor-pointer text-white hover:antialiased`}
  >
    <span className="flex items-center">
      <h1 className="text-xs lg:text-sm hover:text-msky hover:bg-white">
        {sec4&&sec4.status?sec4.title:""}
      </h1>
    </span>
  </li>
  <h1 className="mx-3 text-white">|</h1>
  <li
    onClick={onSupply}
    className={` cursor-pointer text-white hover:antialiased`}
  >
    <span className="flex items-center">
      <h1 className="text-xs lg:text-sm hover:text-msky hover:bg-white">
        {sec5&&sec5.status?sec5.title:""}
      </h1>
    </span>
  </li>
  <h1 className="mx-3 text-white">|</h1>
  <li
    onClick={onProcess}
    className={` cursor-pointer text-white hover:antialiased`}
  >
    <span className="flex items-center">
      <h1 className="text-xs lg:text-sm hover:text-msky hover:bg-white">
        {sec6&&sec6.status?sec6.title:""}
      </h1>
    </span>
  </li>
  <h1 className="mx-3 text-white">|</h1>
  <li
    onClick={onApplication}
    className={` cursor-pointer text-white hover:antialiased`}
  >
    <span className="flex items-center">
      <h1 className="text-xs lg:text-sm hover:text-msky hover:bg-white">
        {sec7&&sec7.status?sec7.title:""}
      </h1>
    </span>
  </li>
  <h1 className="mx-3 text-white">|</h1>
  <li
    onClick={onHigh}
    className={` cursor-pointer text-white hover:antialiased`}
  >
    <span className="flex items-center">
      <h1 className="text-xs lg:text-sm hover:text-msky hover:bg-white">
        {sec8&&sec8.status?sec8.title:""}
      </h1>
    </span>
  </li>
</ul>
    </div>
  );
};

export default Services;
