import fav from "../../assets/svg/favH.svg";
import favr from "../../assets/svg/favF.svg";
import dp from "../../assets/images/dp.webp";
import elp from "../../assets/images/elpe.webp";
import pen from "../../assets/images/pen.webp";
import BrandPartner from "../../component/common/brandPartner";
import spinner from "../../assets/svg/spinner.svg";
import Autoplay from "embla-carousel-autoplay";
import React,{ useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../component/NavBar/NavBar";
import NavbarShops from "../../component/navBarShop/navBar";
import Footer from "../../component/Footer/Footer";

import SignUp from "../../component/signup/signUp";
import { SERVER_URL } from "../../constants/apiLinks";
// Product images start
import cartImage from "../../assets/svg/cartw.svg";
import favr2 from "../../assets/svg/heart.svg";
import favr3 from "../../assets/svg/heartw.svg";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../component/ui/carousel";
import { Sheet, SheetContent, SheetTrigger } from "../../component/ui/sheet";
import hamburgerb from "../../assets/svg/hamburgerb.svg";
import AccordionCustom from "../../component/accordion/accordion";
import searchW from "../../assets/svg/mag-w.svg";

// fetch data func
const fetchData = async () => {
  try {
    const responseHero = await fetch(`${SERVER_URL}/api/layout1/HPHeroSec`);
    const dataHero = await responseHero.json();

    const responseCrousal = await fetch(`${SERVER_URL}/api/user/other/HPCrousal`);
    const dataCrousal = await responseCrousal.json();

    const responseQualityBadges = await fetch(`${SERVER_URL}/api/user/other/HPQualityBadge`);
    const dataQualityBadges = await responseQualityBadges.json();

    const responseQualityProducts = await fetch(`${SERVER_URL}/api/layout1/qualityPHI`);
    const dataQualityProducts = await responseQualityProducts.json();

    const responseQualityProductsSec1 = await fetch(`${SERVER_URL}/api/layout1/qualityPSec1`);
    const dataQualityProductsSec1 = await responseQualityProductsSec1.json();

    const responseQualityProductsSec2 = await fetch(`${SERVER_URL}/api/layout1/qualityPSec2`);
    const dataQualityProductsSec2 = await responseQualityProductsSec2.json();

    const responseIconBox1 = await fetch(`${SERVER_URL}/api/layout1/HPIconBox1`);
    const dataIconBox1 = await responseIconBox1.json();

    const responseIconBox2 = await fetch(`${SERVER_URL}/api/layout1/HPIconBox2`);
    const dataIconBox2 = await responseIconBox2.json();

    const responseIconBox3 = await fetch(`${SERVER_URL}/api/layout1/HPIconBox3`);
    const dataIconBox3 = await responseIconBox3.json();

    const responseIconBox4 = await fetch(`${SERVER_URL}/api/layout1/HPIconBox4`);
    const dataIconBox4 = await responseIconBox4.json();

    return {
      dataHero,
      dataCrousal,
      dataQualityBadges,
      dataQualityProducts,
      dataQualityProductsSec1,
      dataQualityProductsSec2,
      dataIconBox1,
      dataIconBox2,
      dataIconBox3,
      dataIconBox4,
    };
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};


function IndexNew() {
  const navigate = useNavigate();
  const handleShop = () => {
    navigate("/shop");
  };
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hero, setHero] = useState<any>(null);
  const [crousal, setCrousal] = useState<any>(null);
  const [qualityBadges, setQualityBadges] = useState<any>(null);
  const [qualityProducts, setQualityProducts] = useState<any>(null);
  const [qualityProductsSec1, setQualityProductsSec1] = useState<any>(null);
  const [qualityProductsSec2, setQualityProductsSec2] = useState<any>(null);
  const [iconBox1, setIconBox1] = useState<any>(null);
  const [iconBox2, setIconBox2] = useState<any>(null);
  const [iconBox3, setIconBox3] = useState<any>(null);
  const [iconBox4, setIconBox4] = useState<any>(null);
 const [loading1, setLoading1] = useState(true);
 
 useEffect(() => {
  if (crousal && crousal.length > 0) {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % crousal.length);
    }, 3000);

    return () => clearInterval(interval);
  }
}, [crousal]);

  useEffect(() => {
    setLoading(true);

    fetchData().then((data) => {
      if (data) {
        setHero(data.dataHero.data[0]);
        setCrousal(data.dataCrousal.data);
        setQualityBadges(data.dataQualityBadges.data);
        setQualityProducts(data.dataQualityProducts.data[0]);
        setQualityProductsSec1(data.dataQualityProductsSec1.data[0]);
        setQualityProductsSec2(data.dataQualityProductsSec2.data[0]);
        setIconBox1(data.dataIconBox1.data[0]);
        setIconBox2(data.dataIconBox2.data[0]);
        setIconBox3(data.dataIconBox3.data[0]);
        setIconBox4(data.dataIconBox4.data[0]);
      }
      setLoading1(false); // End loading
    });
    // console.log("crousal",JSON.stringify(crousal,null,2));
  }, []);

  const handleNavigation = () => {
    navigate("/cart");
  };
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isGrid, setIsGrid] = useState(false);

  const [isToCart, setIsToCart] = useState<{ [key: number]: boolean }>({});
  const [favorites, setFavorites] = useState<{ [key: number]: boolean }>({});
  const [products, setProducts] = useState<any[]>([]);
  const [resetProducts, setResetProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1); // Current page state
  const [itemsPerPage, setItemsPerPage] = useState<number>(8);
  const [searchTerm, setSearchTerm] = useState<string>(''); // For search input
  const [mobSearch, setMobSearch] = useState<string>(''); 
  const handleFav = (productId: number) => {
    setFavorites((prevFavorites) => ({
      ...prevFavorites,

      [productId]: !prevFavorites[productId],
    }));
  };

 

  

 


  const handleCart = (productId: number) => {
    setIsToCart((pervCart) => ({
      ...pervCart,
      [productId]: !pervCart[productId],
    }));
  };
  useEffect(() => {
    const fetchProducts = async () => {
      const slugs = ["copy-printer-paper", "bulletin-boards"];
      const requests = slugs.map((slug) =>
        fetch(`${SERVER_URL}/api/user/productbyslugcategory/${slug}?page=1&limit=4`).then(
          (res) => res.json()
        )
      );

      try {
        const results = await Promise.all(requests);
        // Combine the products from both responses into a single array
        const combinedProducts = results.flatMap((result) => result.data);
        setProducts(combinedProducts);
        // console.log("combine",combinedProducts);
        
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);
  async function fetchProducts() {
    try {
      setLoading(true);
      const response = await fetch(
        `${SERVER_URL}/api/user/product?page=${currentPage}&limit=${itemsPerPage}`
      );
      const data = await response.json();
      console.log("PR", data);

      setResetProducts(data.data);

    } catch (error) {
      console.log("ERROR", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchProducts();
  }, [ currentPage, itemsPerPage]);
  const plugin = React.useRef(
    Autoplay({ delay: 2000, stopOnInteraction: true })
  );
  return (
    <>
    {loading1?(
      <div className="flex flex-col h-[50vh] items-center justify-center">
      <img src={spinner} className="h-24 w-44" alt="Loading" />
    </div>
    ):(
    <div className="relative">
    {/* Nvbar section start */}
    <NavBar
      onAboutPress={() => navigate("/?section=aboutus")}
      onServicePress={() => navigate("/?section=service")}
    />
    {/* Nvbar section End */}
    <div className="top-[70px] min-[400px]:top-[70px] min-[767px]:top-[73px] min-[768px]:top-[83px] min-[1440px]:top-[83px]  z-50 fixed w-full bg-white">
      <NavbarShops />
       {/* Mobile Menu start */}
<div className="p-4 min-[1440px]:hidden">
     <Sheet>
              <SheetTrigger asChild>
                <button className="min-[1440px]:hidden inline-flex gap-2 items-center font-bold text-black text-[14px] font-Noto ">
                  <img src={hamburgerb} className="h-5 w-5" alt="" />
                  Menu
                </button>
              </SheetTrigger>
              <SheetContent
                side={"left"}
                className="no-scrollbar flex h-full w-full flex-col gap-3 overflow-scroll bg-gray-200 max-w-[300px] px-0"
              >
                <div className="flex flex-col gap-2 pt-10">
                  <div className="px-4">
                    <div className="flex items-stretch gap-4 p-0 m-0 overflow-hidden rounded  bg-app_bg_gray border-none outline-none ">
                      <input
                        type="text"
                        className="w-full bg-transparent px-3 my-0 py-3 border-none outline-none focus:border-none"
                        placeholder="Search"
												onChange={(e)=>setMobSearch(e.target.value)}
                      />
                      <button className="flex items-center self-stretch flex-col w-20 justify-center   bg-msky h-auto">
                        <img src={searchW} className="h-7 w-7" alt="" 
												onClick={() => navigate(`/shop`)}
												/>
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col gap-4">
                    <AccordionCustom />
                  </div>
                </div>
              </SheetContent>
    </Sheet>
</div>
            {/* Mobile Menu End */}
    </div>
    <div className="bg-app_bg_gray bg-opacity-100 h-[90px] min-[400px]:h-[129px]  min-[1440px]:h-[150px]"></div>
    <div
      style={{
        backgroundImage: `url(${hero.image})`,
      }}
    className="min-h-[630px] bg-cover bg-opacity-15 flex flex-col  justify-center ">
      <div className="z-0 mx-auto flex flex-col-reverse md:flex-row justify-between h-full w-[95%] items-center gap-4 px-4 font-poppins md:w-[85%] py-10 ">
        <div className="flex-1 flex flex-col">
          {/* <span className="text-[15px] font-Noto text-msky font-semibold leading-[15px] ">
            BOTHUT
          </span> */}
          <span className="font-Noto  text-[22px] leading-6 sm:text-[30px] sm:leading-[35px] lg:text-[46px] max-w-[600px] lg:leading-[55px] font-semibold mt-4">
           {hero?.title}
          </span>
          <span className="font-Noto text-[16px] leading-7 mt-4 uppercase text-mdark font-semibold">
          {hero?.description}
          </span>
          <button
            className="bg-black self-start text-[15px] text-app_white font-Noto px-1 min-w-[150px] h-[50px] justify-center py-2 rounded-[2px] inline-flex gap-1 items-center mt-12 hover:bg-msky"
            onClick={handleShop}
          >
            <img src={cartImage} className="w-6 h-6" alt="" />
            <span>Shop Now</span>
          </button>
        </div>
        <div className="flex-1">
          <img
            src={crousal&&crousal[currentIndex].image}
            className="max-h-[500px]  transition-opacity duration-1000"
            alt={`Slide ${currentIndex + 1}`}
          />
        </div>
      </div>
    </div>
{/* 
    <div className="no-scrollbar relative z-0 mx-auto flex h-full w-[95%] items-start gap-4 px-4 font-poppins md:w-[85%] pt-10">
      <div className="grid grid-cols-1 md:grid-cols-3 w-full gap-4">
        <div className="p-1 shadow-xl  border-2 border-white bg-[#e7e3e6]">
          <div className="flex gap-2 text-[14px] font-semibold text-msky items-center justify-center font-Noto">
            <div className="flex flex-col justify-center pl-5 shrink-0 ">
              <span className="uppercase text-[12px]">
                Compatible Ribbon{" "}
              </span>
              <span className="font-bold pt-1 pb-2 text-[15px]">
                Dataproducts R3027{" "}
              </span>
              <span>Single : $1.65</span>
              <span>4 Pack : $5.98</span>
              <span> 6 Pack : $8.98</span>
              <span>12 Pack : $17.49</span>
            </div>
            <div className="">
              <img src={dp} className="w-40 h-full object-cover" alt="" />
            </div>
          </div>
        </div>
        <div className="p-1 shadow-xl  border-2 border-white bg-msky">
          <div className="flex gap-2 text-white items-center justify-center">
            <div className="flex flex-col justify-center pl-5 shrink-0 ">
              <span className="uppercase text-[12px]">
                Compatible Ribbon{" "}
              </span>
              <span className="font-bold pt-1 pb-2 text-[15px]">
                Dataproducts R3027{" "}
              </span>
              <span>Single : $1.65</span>
              <span>4 Pack : $5.98</span>
              <span> 6 Pack : $8.98</span>
              <span>12 Pack : $17.49</span>
            </div>
            <div className="">
              <img src={elp} className="w-40 h-full object-cover" alt="" />
            </div>
          </div>
        </div>
        <div className="p-1 shadow-xl  border-2 border-white bg-[#e7e3e6]">
          <div className="flex gap-2 text-[14px] font-semibold text-msky items-center justify-center font-Noto">
            <div className="flex flex-col justify-center pl-5 shrink-0 ">
              <span className="uppercase text-[12px]">
                Compatible Ribbon{" "}
              </span>
              <span className="font-bold pt-1 pb-2 text-[15px]">
                Dataproducts R3027{" "}
              </span>
              <span>Single : $1.65</span>
              <span>4 Pack : $5.98</span>
              <span> 6 Pack : $8.98</span>
              <span>12 Pack : $17.49</span>
            </div>
            <div className="">
              <img src={pen} className="w-40 h-full object-cover" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div> */}

    <div className="no-scrollbar relative z-0 mx-auto flex h-full w-[95%] items-start gap-4 px-4 font-poppins md:w-[85%] ">
      <div className="flex flex-[5] flex-col gap-5">
        <div className="flex items-center gap-4 z-0 relative py-10">
          <h2 className="font-poppins text-[24px] z-[-1] shrink-0 font-semibold uppercase">
            Featured Products
          </h2>
          <div className="bg-[#D5D5D5] h-[2px] rounded-full w-full z-0"></div>
        </div>
       
        {/* Product start */}
        {loading ? (
          <div className="flex flex-col h-[50vh] items-center justify-center">
            <img src={spinner} className="h-24 w-44" alt="Favorited" />
          </div>
        ) : (
          <>
            {products && products.length < 1 ? (
              <div className="flex flex-col h-[50vh] items-center justify-center">
                <h1 className="font-semibold text-xl">No result found</h1>
                <h1 className="text-lg">Try different keyword</h1>
              </div>
            ) : (
              <div
                className={`grid grid-cols-1 gap-x-6 gap-y-4 ${
                  isGrid
                    ? "grid-cols-1"
                    : "md:grid-cols-2 min-[1280px]:grid-cols-3 min-[1380px]:grid-cols-4"
                }`}
              >
                {products?.map((item) => {
                  // console.log(item._id);
                  return (
                    <div
                      key={item.id}
                      className={`group relative flex w-full flex-col self-start outline-gray-100 transition-all delay-100 duration-150 hover:shadow-md hover:outline hover:outline-2 border p-1`}
                    >
                      <div className="relative justify-center">
                        <img
                          src={item.imageUrls[0]}
                          className={`mx-auto mt-1 h-[240px] w-full min-w-[250px] shrink-0 bg-app_white max-w-[300px] ${
                            isGrid ? "max-w-[240px]" : ""
                          }`}
                          alt=""
                        />
                        <div
                          className={`${
                            isToCart[item.id] ? "bg-black" : ""
                          } absolute bottom-0 mt-4 hidden w-full cursor-pointer bg-app_primary text-app_white group-hover:block`}
                          onClick={() => {
                            handleCart(item.id);
                          }}
                        >
                          <div className="flex justify-center">
                            {isToCart[item.id] ? (
                              <Link
                                to={"/cart"}
                                className="h-full w-full py-3 text-center"
                              >
                                View Cart
                              </Link>
                            ) : (
                              <span className="h-full w-full py-3 text-center">
                                Add to Cart
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="absolute right-1 top-1">
                        <button
                          className="rounded-full bg-slate-100 p-2"
                          onClick={() => handleFav(item.id)}
                        >
                          {favorites[item.id] ? (
                            <img
                              src={favr}
                              className="h-6 w-6"
                              alt="Not Favorited"
                            />
                          ) : (
                            <img
                              src={fav}
                              className="h-6 w-6"
                              alt="Favorited"
                            />
                          )}
                        </button>
                      </div>
                      <div className="flex flex-col px-3 py-4">
                        {/* Preview Images start */}
                        <button
                          className={`flex items-center gap-2 ${
                            isGrid ? "justify-center" : ""
                          }`}
                        >
                          {item.imageUrls.map(
                            (prodImage: any, index: any) => {
                              // console.log(`${prodImage}-${index}`);
                              return (
                                <button
                                  key={`${index}`}
                                  onClick={() => setSelectedImage(prodImage)}
                                >
                                  <img
                                    src={prodImage}
                                    className="h-10 w-10 shrink-0 border p-1"
                                    alt=""
                                  />
                                </button>
                              );
                            }
                          )}
                        </button>
                        {/* Preview Images End */}
                        <div
                          className="group-[] flex flex-col items-center justify-center gap-1 py-4"
                        >
                          <span onClick={() => navigate(`/product-category/${item.category.slug}`)} className="group-hover:cursor-pointer group-hover:text-app_primary text-[12px] font-light italic text-app_dark_light">
                            {item.category ? item.category.name : ""}
                          </span>
                          <span onClick={() => navigate(`/product/${item.id}`)} className="group-hover:cursor-pointer group-hover:text-app_primary text-center text-[15px] font-medium text-app_black">
                            {item.productName}
                          </span>
                        </div>
                        {/* Price start */}
                        <span className="text-center text-app_primary">
                          {item.productPrice}
                        </span>
                        {/* Price End */}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
        {/* Product End */}
      </div>
    </div>
    <div className="flex justify-center mt-4 mb-10">
      <button type="button" className="bg-app_primary text-white text-lg px-4 py-2" onClick={() => navigate(`/shop`)}>Explore more products</button>
    </div>
    {/* Product Ends */}
    {/* Parallax start */}
    <div className="relative">
      <div    style={{
        backgroundImage: `url(${qualityProducts?.image})`,
      }} className="relative min-h-[2400px] xl:min-h-screen bg-center bg-cover"></div>
      <div className="absolute bg-opacity-70 top-0 w-full h-full bg-black">
        <div
          className="
        no-scrollbar  py-10 z-0 mx-auto w-[95%]   font-poppins md:w-[85%] text-white flex justify-center items-center flex-col h-full"
        >
          <span className="text-[18px] xl:text-[22px] xl:leading-[22px] underline xl:underline-offset-[16px] mb-6 font-medium  uppercase">
            {qualityProducts?.title}
          </span>
          <span className="xl:text-[42px] text-[18px]  tracking-[2px] xl:leading-[42px] font-bold uppercase py-4 text-center">
            {qualityProductsSec1?.title}
          </span>
          <span className="text-[18px] xl:text-[22px] xl:leading-[40px] max-w-screen-xl  font-medium uppercase tracking-[2px] text-center">
          {qualityProductsSec1?.description}
          </span>
  
          <div className="flex gap-5 flex-col items-center sm:flex-row  mt-4 lg:mt-10 mb-5">
            <button className="w-[215px] h-[60px] text-[14px] font-bold leading-[14px] rounded-[2px] bg-white hover:text-white hover:bg-msky text-app_secondary uppercase inline-flex justify-center items-center gap-2 transition-all delay-300 duration-300 group">
              {/* Image before hover */}
              <img
                src={favr2}
                className="h-5 w-5 group-hover:hidden"
                alt=""
              />
              {/* Image after hover */}
              <img
                src={favr3}
                className="h-5 w-5 hidden group-hover:block"
                alt=""
              />
              Ask For Quote
            </button>
            <button className="text-[14px] font-bold leading-[14px] rounded-[2px]  text-app_white uppercase inline-flex justify-center items-center border-2 border-white w-[160px] h-[60px] hover:border-msky hover:bg-msky transition-all delay-300 duration-300">
              CONTACT US
            </button>
          </div>
          <span className="xl:text-[22px] xl:leading-[22px] underline xl:underline-offset-[16px] xl:mb-6 xl:mt-6 font-medium uppercase">
          {qualityProductsSec2?.title}
          </span>
          <span className="text-[15px] xl:text-[22px] xl:leading-10 font-medium uppercase tracking-[2px] max-w-screen-xl text-center xl:mt-10 mt-4 mb-2 xl:mb-7">
            {qualityProductsSec2?.description}
          </span>
          <div className="flex flex-wrap gap-8">
            {qualityBadges.map((item:any) => {
              return (
                <img
                key={item._id}
                  src={item.image}
                  className="w-20 h-20 md:w-24 md:h-24 lg:w-28 lg:28 2xl:w-36 2xl:h-36"
                  alt=""
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
    {/* Parallax End */}

    {/* Latest Product start */}
    <div className="no-scrollbar relative z-0 mx-auto flex h-full w-[95%] items-start flex-col gap-4 px-4 font-poppins md:w-[85%] pt-20">
      <div className="flex items-center gap-4 z-0 relative py-10 w-full">
        <h2 className="font-poppins text-[24px] z-[-1] shrink-0 font-semibold uppercase">
          Latest Products
        </h2>
        <div className="bg-[#D5D5D5] h-[2px] rounded-full w-full z-0"></div>
      </div>
      <Carousel
          className="w-full "
          opts={{
            align: "start",
            loop: true,
          }}
          plugins={[plugin.current]}
          onMouseEnter={plugin.current.stop}
          onMouseLeave={plugin.current.reset}
        >
          <CarouselContent className="w-full">
            {resetProducts?.map((item) => {
              // console.log(item._id);
              return (
                <CarouselItem
                  key={item._id}
                  className={`basis-full min-[700px]:basis-1/2 lg:basis-1/3 2xl:basis-1/4`}
                >
                  <div className="group relative flex w-full h-full flex-col self-start outline-gray-100 transition-all delay-100 duration-150 hover:shadow-md hover:outline md:justify-between hover:outline-2 border p-1">
                    <div className="relative justify-center">
                      <img
                        src={item.imageUrls[0]}
                        className={`mx-auto mt-1 h-[200px] w-full min-w-[200px] shrink-0 bg-app_white max-w-[200px] ${
                          isGrid ? "max-w-[200px]" : ""
                        }`}
                        alt=""
                      />
                      <div
                        className={`${
                          isToCart[item._id] ? "bg-black" : ""
                        } absolute bottom-0 mt-4 hidden w-full cursor-pointer bg-app_primary text-app_white group-hover:block`}
                        onClick={() => {
                          handleCart(item._id);
                        }}
                      >
                        <div className="flex justify-center">
                          {isToCart[item._id] ? (
                            <Link
                              to={"/cart"}
                              className="h-full w-full py-3 text-center"
                            >
                              View Cart
                            </Link>
                          ) : (
                            <span className="h-full w-full py-3 text-center">
                              Add to Cart
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="absolute right-1 top-1">
                      <button
                        className="rounded-full bg-slate-100 p-2"
                        onClick={() => handleFav(item._id)}
                      >
                        {favorites[item._id] ? (
                          <img
                            src={favr}
                            className="h-6 w-6"
                            alt="Not Favorited"
                          />
                        ) : (
                          <img src={fav} className="h-6 w-6" alt="Favorited" />
                        )}
                      </button>
                    </div>
                    <div className="flex flex-col px-3 py-4">
                      {/* Preview Images start */}
                      <button
                        className={`flex items-center gap-2 ${
                          isGrid ? "justify-center" : ""
                        }`}
                      >
                        {item.imageUrls.map((prodImage: any, index: any) => {
                          // console.log(`${prodImage}-${index}`);
                          return (
                            <button
                              key={`${index}`}
                              onClick={() => setSelectedImage(prodImage)}
                            >
                              <img
                                src={prodImage}
                                className="h-10 w-10 shrink-0 border p-1"
                                alt=""
                              />
                            </button>
                          );
                        })}
                      </button>
                      {/* Preview Images End */}
                      <div
                        className="group-[] flex flex-col items-center justify-center gap-1 py-4">
                        <span onClick={() => navigate(`/product-category/${item.category.slug}`)} className="group-hover:cursor-pointer group-hover:text-app_primary text-[12px] font-light italic text-app_dark_light">
                          {item.category ? item.category.name : item.brand}
                        </span>
                        <span onClick={() => navigate(`/product/${item._id}`)} className="group-hover:cursor-pointer group-hover:text-app_primary text-center text-[15px] font-medium text-app_black">
                          {item.productName}
                        </span>
                      </div>
                      {/* Price start */}
                      <span className="text-center text-app_primary">
                        {item.productPrice}
                      </span>
                      {/* Price End */}
                    </div>
                  </div>
                </CarouselItem>
              );
            })}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
    </div>
    {/* Latest Product End */}

    {/* Feature start */}
    <div className="no-scrollbar relative z-0 mx-auto flex h-full w-[95%] items-start gap-4 px-4 font-poppins md:w-[85%] pt-20">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 w-full gap-8">
        <div className="flex flex-col bg-[#5b87c4] items-center text-white  border rounded-sm overflow-hidden shadow p-4">
          <div className="p-4 ">
            <img src={iconBox1?.image} className="w-20 h-20" alt="" />
          </div>
          <div className="px-4 flex flex-col items-center">
            <h3 className="text-base uppercase tracking-wider">
              {iconBox1?.title}
            </h3>
            <p className="text-sm">{iconBox1?.description}</p>
          </div>
        </div>
        <div className="flex flex-col bg-[#c92128] items-center text-white  border rounded-sm overflow-hidden shadow p-4">
          <div className="p-4 ">
            <img src={iconBox2?.image} className="w-20 h-20" alt="" />
          </div>
          <div className="px-4 flex flex-col items-center">
            <h3 className="text-base uppercase tracking-wider">
            {iconBox2?.title}
            </h3>
            <p className="text-sm">{iconBox2?.description}</p>
          </div>
        </div>
        <div className="flex flex-col bg-[#1346af] items-center text-white  border rounded-sm overflow-hidden shadow p-4">
          <div className="p-4 ">
            <img src={iconBox3?.image} className="w-20 h-20" alt="" />
          </div>
          <div className="px-4 flex flex-col items-center">
            <h3 className="text-base uppercase tracking-wider">
            {iconBox3?.title}
            </h3>
            <p className="text-sm">{iconBox3?.description}</p>
          </div>
        </div>
        <div className="flex flex-col bg-[#3d3d3d] items-center text-white  border rounded-sm overflow-hidden shadow p-4">
          <div className="p-4 ">
            <img src={iconBox4?.image} className="w-20 h-20" alt="" />
          </div>
          <div className="px-4 flex flex-col items-center">
            <h3 className="text-base uppercase tracking-wider">
            {iconBox4?.title}
            </h3>
            <p className="text-sm">{iconBox4?.description}</p>
          </div>
        </div>
      </div>
    </div>
    {/* Feature End */}

    {/* Brands partner start */}
    <BrandPartner />
    {/* Brands partner End */}

    {/* Sign Up section start */}
    <SignUp />
    {/* Sign Up section End */}

    {/* Footer start */}
    <Footer
      onAboutPress={() => navigate("/?section=aboutus")}
      onServicePress={() => navigate("/?section=service")}
    />

    {/* Selected Image view */}

    {selectedImage && (
      <div className="z-[1000000] fixed left-0 top-0 right-0 h-screen bg-gray-900/60 flex flex-col justify-center items-center p-4 gap-4">
        <button
          type="button"
          className="bg-red-500 fixed top-2 text-white rounded-sm p-3 self-end"
          onClick={() => setSelectedImage(null)}
        >
          close
        </button>
        <div className="flex-1 self-stretch grid place-content-center">
          <img
            src={selectedImage}
            alt={selectedImage}
            className="object-contain"
          />
        </div>
      </div>
    )}

    {/* Footer End */}
  </div>)}
    
    </>
  );
}

export default IndexNew;
