import { useEffect, useState } from 'react';

import { SERVER_URL } from '../../constants/apiLinks';
import { Link } from 'react-router-dom';
import cd from "../../assets/svg/cd.svg";

interface Category {
  id: string;
  name: string;
  slug:string;
  status: number;
  children?: Category[];
}

const BreakroomMenu = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [hoveredCategoryId, setHoveredCategoryId] = useState<any>(null);
  const [error, setError] = useState<any | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${SERVER_URL}/api/categoryorganize`);
        if (!response.ok) throw new Error('Failed to fetch categories');

        const data: Category[] = await response.json();
        setCategories(data);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;


  return (
   
    <div className="">
    <div className="hidden items-center gap-8 text-sm py-2 min-[1440px]:flex z-[999999999]">
      {categories.map((parentCategory) => (
        <div
          key={parentCategory.id}
          className="relative"
          onMouseEnter={() => setHoveredCategoryId(parentCategory.id)}
          onMouseLeave={() => setHoveredCategoryId(null)}
        >
          {/* Parent Trigger */}
          <div className="flex font-medium py-2">
            <Link to={`/product-category/${parentCategory.slug}`}>
              {parentCategory.name}
            </Link>
            <div className="relative self-end">
              <img src={cd} className="w-4 h-4" alt="" />
            </div>
          </div>

          {/* Dropdown Content */}
          <div
            className={`z-[999999999] pt-2 ${
              hoveredCategoryId === parentCategory.id ? 'flex' : 'hidden'
            } absolute top-full left-0 w-auto bg-white shadow-md flex-col`}
          >
            <ul
              className="z-[999999999] grid grid-cols-1 bg-white md:w-[250px]"
            >
              {parentCategory.children?.map((childCategory) => (
                <li key={childCategory.id} className="flex flex-col">
                  <Link
                    to={`/product-category/${childCategory.slug}`}
                    className="hover:text-app_primary px-4 py-3 text-sm border border-t-0 border-x-0 border-collapse"
                  >
                    {childCategory.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  </div>
  

  );
};

export default BreakroomMenu;
