import React from "react";

import bgImage from "../../assets/images/bg-1.jpg";
import contact from "../../assets/images/smartphone.webp";
import BrandPartner from "../../component/common/brandPartner";
import { useNavigate } from "react-router-dom";
import NavBar from "../../component/NavBar/NavBar";
import Footer from "../../component/Footer/Footer";
import SignUp from "../../component/signup/signUp";
import { SERVER_URL } from "../../constants/apiLinks";
import { useState,useEffect } from "react";
import ScrollToTop from "../../component/scrollToTop/scroll";
function ShippingPolicy() {
  const navigate = useNavigate();
  const [content, setContent] = useState<any>();
  const [pageData, setPageData] = useState<any>();
  useEffect(() => {
    // Fetch privacy policy content
    const fetchContent = async () => {
      try {
        const contentResponse = await fetch(`${SERVER_URL}/api/user/content/shippingPolicyContent`);
        const contentData = await contentResponse.json();
        if (contentData.success === 1 && contentData.data) {
          setContent(contentData.data.data); // Set the HTML string
        }
        const pageResponse = await fetch(`${SERVER_URL}/api/layout1/shippingPolicy`);
        const pageData = await pageResponse.json();
        setPageData(pageData.data[0]);
      } catch (error) {
        console.error("Error fetching the data:", error);
      }
    };

    fetchContent();
  }, []);
  return (
    <>
        <ScrollToTop/>
      {/* Header section start */}
      <NavBar
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />

        {/* hero section start */}
        <div className="h-[200px] w-full z-20 py-32 relative">
        <div className="absolute h-full w-full bg-black top-0 z-30 bg-opacity-70"></div>
        <img
          src={pageData?.image}
          className="w-full h-full absolute top-0 z-0 object-cover"
          alt=""
        />
        <div className="flex items-center h-full relative justify-center  z-50 md:w-[85%] w-[95%] mx-auto">
          <p className="uppercase font-poppins text-[17px] md:text-[26px] font-semibold text-app_white">
            {pageData?.title}
          </p>
        </div>
      </div>
      {/* hero section End */}

   {/* Main Body start */}
   <section className="flex flex-col h-full relative justify-center z-10 md:w-[85%] w-[95%] mx-auto font-poppins px-4 py-10">
        {content ? (
          <div
            className="prose-sm prose-h1:font-semibold  prose-h1:text-lg prose-li:list-disc prose-a:text-app_primary prose-a:no-underline prose-a:hover:text-app_secondary prose-a:hover:underline"
            dangerouslySetInnerHTML={{ __html: content }} // Injecting the HTML content
          />
        ) : (
          <p>Loading content...</p>
        )}
      </section>
      {/* Main Body End */}
      {/* Brands partner start */}
      <BrandPartner />
      {/* Brands partner End */}

      {/* Sign Up section start */}
      <SignUp />
      {/* Sign Up section End */}
      {/*Main Body End  */}

      {/* Footer start */}
      <Footer
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />

      {/* Footer End */}
    </>
  );
}

export default ShippingPolicy;
