import React, { useEffect, useState } from "react";
import navList from "../../data/nav.json";
import { IoMdMenu } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from "../../constants/apiLinks";
const NavBar = ({ onAboutPress, onServicePress }) => {
  const [navBg, setNavBg] = useState(false);
  const navigation = useNavigate();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [navI1, setNavI1] = useState(null);
  const [navI2, setNavI2] = useState(null);
  const [navI3, setNavI3] = useState(null);
  const [navI4, setNavI4] = useState(null);
  const [navI5, setNavI5] = useState(null);
  const [intialNav, setIntialNav] = useState(navList);
  
  const fetchNavItem = async (navItem) => {
    try {
      const response = await fetch(`${SERVER_URL}/api/layout1/${navItem}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error(`Failed to fetch ${navItem}`);

      return await response.json();
    } catch (error) {
      console.error(`Error fetching ${navItem}:`, error);
      return null; // Return null if there's an error
    }
  };

  const fetchAllNavItems = async () => {
    const [navI1Data, navI2Data, navI3Data, navI4Data, navI5Data] = await Promise.all([
      fetchNavItem('navI1'),
      fetchNavItem('navI2'),
      fetchNavItem('navI3'),
      fetchNavItem('navI4'),
      fetchNavItem('navI5'),
    ]);

    setNavI1(navI1Data.data[0]);
    setNavI2(navI2Data.data[0]);
    setNavI3(navI3Data.data[0]);
    setNavI4(navI4Data.data[0]);
    setNavI5(navI5Data.data[0]);
  };

  useEffect(() => {
    fetchAllNavItems();
  }, []); 
  useEffect(() => {
    if (navI1 && navI2 && navI3 && navI4 && navI5) {
      const arr = [navI1, navI2, navI3, navI4, navI5];
      /* console.log("Arr", JSON.stringify(arr, null, 2));
      console.log("Initial", JSON.stringify(intialNav, null, 2)); */
      
      const updatedNav = intialNav.map(navItem => {
        const matchingItem = arr.find(item => item.keyword === navItem.keyword);
        return {
          ...navItem,
          status: matchingItem ? matchingItem.status : navItem.status
        };
      });
  
      // console.log("Updated Initial Array:", JSON.stringify(updatedNav, null, 2));
      setIntialNav(updatedNav);  
    }
  }, [navI1, navI2, navI3, navI4, navI5]); 
  
  const toggleDrawer = () => {
    setIsDrawerVisible(!isDrawerVisible);
  };

  const changeNavBg = () => {
    window.scrollY >= 10 ? setNavBg(true) : setNavBg(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavBg);
    return () => {
      window.removeEventListener("scroll", changeNavBg);
    };
  }, []);

  return (
    <div
      className={`py-3 flex justify-center   ${
        navBg ? "" : "border-b-[1px]"
      } border-[#00000020]  w-screen  fixed z-30 ${
        navBg ? "bg-mdark" : "bg-[#00000020]"
      }`}
    >
      <div className="flex z-30  items-center justify-between md:w-[85%] w-[75%]">
        <div>
          <img
            onClick={() => {
              navigation("/");
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            alt="bothub"
            className=" w-16 md:w-20  cursor-pointer"
            src={require("../../assets/logo/logo.png")}
          />
        </div>
        <ul
          className={` space-x-8  lg:flex  hidden items-center   ${
            navBg ? "text-white" : "text-white"
          } `}
        >
          {intialNav.slice(0, 4).map((item) => (
            item.status?(
            <li
              onClick={() => {
                if (item.is_route) {
                  if (window.location.pathname === "/") {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }
                  navigation(item.route);
                } else if (item.is_route === false) {
                  if (item.id === 2) {
                    onAboutPress();
                  } else if (item.id === 3) {
                    onServicePress();
                  }
                }
              }}
              className={`${
                window.location.pathname === item.route ? "text-msky" : ""
              } hover:text-msky cursor-pointer`}
              key={item.id || item.keyword}
            >
              <h3>{item.name}</h3>
            </li>
            ):""
          ))}
          <li className="cursor-pointer">
            <button
              onClick={() => {
                navigation("/shop-main");
              }}
              className=" text-white hover:text-msky cursor-pointer"
            >
              Shop
            </button>
          </li>
          <li className="cursor-pointer">
            <button
              onClick={() => {
                navigation("/contact-us");
              }}
              className="py-2 px-3  bg-msky text-white"
            >
              Contact Us
            </button>
          </li>
        </ul>

        <button
          onClick={() => toggleDrawer()}
          className="lg:hidden block text-white bg-mdark p-1 px-2 text-2xl md:text-3xl"
        >
          {isDrawerVisible ? <IoMdClose /> : <IoMdMenu />}
        </button>
        {isDrawerVisible && (
          <div className="fixed left-0 top-[4.5rem] lg:hidden self-center flex items-center overflow-x-hidden transition-transform duration-300 opacity-100">
            <ul className={`items-center bg-mdark w-[100vw]  text-white p-6`}>
              {intialNav.slice(0, 4).map((item) => (
                 item.status?(
                <li
                  onClick={() => {
                    if (item.is_route) {
                      navigation(item.route);
                    } else if (item.is_route === false) {
                      if (item.id === 2) {
                        onAboutPress();
                      } else if (item.id === 3) {
                        onServicePress();
                      }
                    }
                    setIsDrawerVisible(false);
                  }}
                  className={` ${
                    window.location.pathname === item.route ? "text-msky" : ""
                  } hover:text-msky mb-4 cursor-pointer`}
                  key={item.id}
                >
                  <h3>{item.name}</h3>
                </li>
                 ):""
              ))}
              <li className="cursor-pointer">
                <button
                  onClick={() => {
                    navigation("/shop-main");
                  }}
                  className="py-2 text-white hover:text-msky cursor-pointer"
                >
                  Shop
                </button>
              </li>
              <li
                onClick={() => {
                  navigation("/contact-us");
                  setIsDrawerVisible(false);
                }}
                className="cursor-pointer"
              >
                <button className="py-2 px-3    bg-msky text-white">
                  Contact Us
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBar;
