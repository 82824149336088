import React from 'react';
//@ts-ignore
import ImageGallery from "react-image-gallery";
//@ts-ignore
import InnerImageZoom from "react-inner-image-zoom";
import 'react-image-gallery/styles/css/image-gallery.css';
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";

interface ProductImageGalleryProps {
  images: { url: string; thumbnailUrl: string }[];
}

const ProductImageGallery: React.FC<ProductImageGalleryProps> = ({ images }) => {
  const galleryItems = images.map((img) => ({
    original: img.url,
    thumbnail: img.thumbnailUrl,
  }));
  const renderZoomableImage = (item: { original: string }) => {
    return <InnerImageZoom src={item.original} zoomType="hover" />;
  };
  return (
    <div>
    {images.length > 1 ? (
      <ImageGallery
        items={galleryItems}
        showFullscreenButton={false}
        showPlayButton={false}
        thumbnailPosition="bottom"
        renderItem={renderZoomableImage}
        showNav={false}
      />
    ) : (
      // Render a single image with zoom if there's only one
      <InnerImageZoom src={images[0].url} zoomType="hover" />
    )}
  </div>
  );
};

export default ProductImageGallery;
