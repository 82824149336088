import React from "react";

const Map = ({mapLink}) => {
  return (
    <div className="responsive-map relative ">
      <iframe
      title="bothut"
        src={mapLink?mapLink:""}
        className=" h-[30rem] w-full"
        frameborder="0"
        allowfullscreen
      ></iframe>



    </div>
  );
};

export default Map;
