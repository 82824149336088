import React from "react";
import { IoMdCheckmark } from "react-icons/io";
import { LuQuote } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const AboutUs = ({data1,data2,data3,data4,data5,data6}) => {
  const navigate = useNavigate();
  return (
    <div className="grid  grid-cols-1 lg:grid-cols-2 gap-24 py-12 items-center  md:pt-16 lg:pt-16 px-[12%] ">
      <div
        // style={{
        //   backgroundImage: ` url(${require("../../assets/about.png")})`,
        // }}
        className="  relative "
      >
        <img
          className=" w-full h-[80%] object-contain  "
          style={{}}
          alt="aboutus"
          src={data1&&data1.status?data1.image:""}
        />
        <div className="flex  items-end justify-end lg:absolute top-[60%] ">
          <div className="  ">
            <span className="justify-end hidden font-mandhor text-right   lg:flex -mb-4 text-white mr-6 text-4xl  right-0">
              <LuQuote className="" />
            </span>

            <div className="text-white text-sm antialiased bg-msky pt-4 pb-4 px-4">
            {data1&&data1.status?data1.description:""}
              <br />
              <br /> <b className="font-semibold">{data1&&data1.status?data1.title:""}</b>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h3 className="text-2xl  text-msky">{data2&&data1.status?data2.title:""}</h3>

        <h1 className="text-3xl my-2 ">{data2&&data1.status?data2.description:""}</h1>

        <p className="text-msgray ">
        {data3&&data1.status?data3.title:""}
        </p>

        <p className="my-4  py-1 leading-6 text-msgray  border-l-4 border-l-msky pl-8">
        {data3&&data1.status?data3.description:""}
        </p>

        <button
          onClick={() => navigate("/contact-us")}
          className="px-10 mb-8 py-3  bg-msky text-white"
        >
          Get Started
        </button>

        <ul className="text-md gap-2 ">
          <li className="flex items-center antialiased">
            <IoMdCheckmark className="text-msky mr-2" />
            <p className="text-msgray ">
            {data4&&data1.status?data4.title:""}
            </p>
          </li>
          <li className="flex items-center antialiased">
            <IoMdCheckmark className="text-msky mr-2" />
            <p className="text-msgray">
            {data5&&data1.status?data5.title:""}
            </p>
          </li>
          <li className="flex items-center antialiased">
            <IoMdCheckmark className="text-msky mr-2 " />
            <p className="text-msgray">
            {data6&&data1.status?data6.title:""}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AboutUs;
