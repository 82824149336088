import React, { useEffect, useState } from "react";
import "./DynamicDivs.css";
import { useNavigate } from "react-router-dom";

const DynamicDiv = ({ data, dataImg }) => {
  const navigate = useNavigate();
  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (dataImg && dataImg.length > 0) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % dataImg.length);
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [dataImg]);

  const currentImageUrl = dataImg && dataImg.length > 0 ? dataImg[currentImageIndex]?.image : "/assets/dynamic/1.png"; // Default image

  return (
    <div className="relative px-[8%]">
      <div
        className=""
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          zIndex: "-2",
          width: "100%",
          margin: "auto",
          height: "80vh", // Ensure this takes up enough height
          backgroundImage: `url(${currentImageUrl})`, // Dynamically set background image
          backgroundPosition: "center",
          backgroundSize: "cover",
          transition: "background-image 1s ease-in-out", // Smooth transition for background image
        }}
      >
        <div id="color-overlay"></div>
      </div>

      <div className="card1 px-6 md:px-16 lg:px-16">
        <h1 className="text-5xl">{data && data.status ? data.title : ""}</h1>
        <br />
        <p className="text-lg lg:max-w-[60%] text-justify">
          {data && data.status ? data.description : ""}
        </p>

        <br />
        <br />
        <button
          onClick={() => navigate("/contact-us")}
          className="px-8 py-4 text-1xl self-start rounded-sm bg-msky text-white"
        >
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default DynamicDiv;
