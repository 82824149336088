// GridComponent.js

import React, { useState } from "react";
import ApplyNow from "../../ApplyNow/ApplyNow";

const GridComponent = ({data}) => {
  const Boxes = ({ item }) => {
    const [hovered, setHovered] = useState(false);
    return (
      <div
        onMouseLeave={() => setHovered(false)}
        onMouseEnter={() => setHovered(true)}
        key={item.id}
        className="shadow-md rounded-lg p-4 text-center flex flex-col justify-center items-center hover:bg-msky hover:border-[1px] hover:border-white bg-white"
      >
        <span className={` ${hovered ? "text-white" : "text-msky"} text-6xl`}>
          <img
            alt={item.heading}
            className="w-24  "
            style={{
              filter: !hovered
                ? "invert(0.5) sepia(1) saturate(1) hue-rotate(135deg)"
                : " invert(0.5) sepia(1) saturate(1) hue-rotate(175deg) brightness(10)",
            }}
            src={item.icon}
          />
        </span>
        <h1
          className={`my-4 text-xl  ${hovered ? "text-white" : "text-black"} `}
        >
          {item.heading}
        </h1>
        <p className={`text-sm ${hovered ? "text-white" : "text-msgray"}`}>
          {item.deatil}
        </p>
      </div>
    );
  };
  return (
    <div className="container mx-auto my-10 flex justify-center ">
      <div className="grid w-[100%] grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
        {/* Row 1 */}

        {data.map((item) => (
          <Boxes key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
};
const Fifth = ({sec6,content}) => {
  const data = [
    {
      id: 1,
      heading:`${content&&content[1].title?content[1].title:""}`,
      deatil:`${content&&content[1].description?content[1].description:""}`,
      icon: `${content&&content[1].image?content[1].image:""}`,
    },
    {
      id: 2,
      heading:`${content&&content[2].title?content[2].title:""}`,
      deatil:`${content&&content[2].description?content[2].description:""}`,
      icon: `${content&&content[2].image?content[2].image:""}`,
    },
    {
      id: 3,
      heading:`${content&&content[3].title?content[3].title:""}`,
      deatil:`${content&&content[3].description?content[3].description:""}`,
      icon: `${content&&content[3].image?content[3].image:""}`,
    },
    {
      id: 4,
      heading:`${content&&content[4].title?content[4].title:""}`,
      deatil:`${content&&content[4].description?content[4].description:""}`,
      icon: `${content&&content[4].image?content[4].image:""}`,
    },
    {
      id: 5,
      heading:`${content&&content[5].title?content[5].title:""}`,
      deatil:`${content&&content[5].description?content[5].description:""}`,
      icon: `${content&&content[5].image?content[5].image:""}`,
    },
    {
      id: 6,
      heading:`${content&&content[6].title?content[6].title:""}`,
      deatil:`${content&&content[6].description?content[6].description:""}`,
      icon: `${content&&content[6].image?content[6].image:""}`,
    },
    {
      id: 7,
      heading:`${content&&content[7].title?content[7].title:""}`,
      deatil:`${content&&content[7].description?content[7].description:""}`,
      icon: `${content&&content[7].image?content[7].image:""}`,
    },
    {
      id: 8,
      heading:`${content&&content[8].title?content[8].title:""}`,
      deatil:`${content&&content[8].description?content[8].description:""}`,
      icon: `${content&&content[8].image?content[8].image:""}`,
    },
  ];
  return (
    <>
     {sec6&&sec6.status?(
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${sec6&&sec6.image?sec6.image:""})`,
      }}
      className="  bg-fixed bg-cover text-white  py-20 px-[12%]"
    >
      <h1 className="font-mandhor text-[120px] text-[#6EC1E470]">{content&&content[0].title?content[0].title:""}
</h1>

      <h2 className="text-white text-4xl md:text-5xl  lg:text-5xl -mt-8">
        {sec6&&sec6.description?sec6.description:""}
      </h2>

      <p className="text-white mt-6">
        {content&&content[0].description?content[0].description:""}
      </p>
      <GridComponent data={data} />

      <ApplyNow />
    </div>
    ):("")}
    </>
  );
};

export default Fifth;
