import React, { useState, useEffect, useRef } from "react";
import NavBar from "../component/NavBar/NavBar";
import DynamicDiv from "../component/DynamicDiv/DynamicDiv";
import AboutUs from "../component/AboutUs/AboutUs";
import Services from "../component/Services/Services";
import IT from "../component/IT/IT";
import One from "../component/Services/Sections/One";
import Second from "../component/Services/Sections/Second";
import Seven from "../component/Services/Sections/Seven";
import Forth from "../component/Services/Sections/Forth";
import Fifth from "../component/Services/Sections/Fifth";
import Third from "../component/Services/Sections/Third";
import Six from "../component/Services/Sections/Six";
import Footer from "../component/Footer/Footer";
import { useSearchParams } from "react-router-dom";
import Initial from "../component/Services/Sections/Initial";
import {SERVER_URL} from "../constants/apiLinks";
import spinner from "../assets/svg/spinner.svg";

const Home = () => {
  const aboutRef = useRef();
  const serviceRef = useRef();
  const initialRef = useRef();
  const oneRef = useRef();
  const secondRef = useRef();
  const thirdRef = useRef();
  const forthRef = useRef();
  const fifthRef = useRef();
  const sixRef = useRef();
  const sevenRef = useRef();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("section"); // "testCode"
//Aq deifned states
const [indexPageHero, setIndexPageHero] = useState(null);
const [indexPageCarousal, setIndexPageCarousal] = useState(null);
const [loading,setLoading] = useState(false);
const [indexPageSec2, setIndexPageSec2] = useState(null);
const [indexPageAbousUs1, setIndexPageAbousUs1] = useState(null);
const [indexPageAbousUs2, setIndexPageAbousUs2] = useState(null);
const [indexPageAbousUsB1, setIndexPageAbousUsB1] = useState(null);
const [indexPageAbousUsB2, setIndexPageAbousUsB2] = useState(null);
const [indexPageAbousUsB3, setIndexPageAbousUsB3] = useState(null);
const [indexPageOurService, setIndexPageOurService] = useState(null);
const [indexPageOurServiceHeadingOnly, setindexPageOurServiceHeadingOnly] = useState(null);
const [indexPageITSol, setindexPageITSol] = useState(null);
const [indexPageLastText, setindexPageLastText] = useState(null);
const [indexPageLastSection, setindexPageLastSection] = useState(null);
const [indexPageITProducts, setIndexPageITProducts] = useState(null);
const [indexPageBusinessStrategy, setIndexPageBusinessStrategy] = useState(null);
const [indexPageDigitalCommerce, setIndexPageDigitalCommerce] = useState(null);
const [indexPageTechnologyInnovation, setIndexPageTechnologyInnovation] = useState(null);
const [indexPageSupplyChainManagement, setIndexPageSupplyChainManagement] = useState(null);
const [indexPageBusinessProcessOutsourcing, setIndexPageBusinessProcessOutsourcing] = useState(null);
const [indexPageApplicationServices, setIndexPageApplicationServices] = useState(null);
const [indexPageHighProfilePR, setIndexPageHighProfilePR] = useState(null);
////////////////LAYOUT2///////////////////////////////////////////////
const [IPITProducts, setIPITProducts] = useState(null);
const [IPBusinessStrategy, setIPBusinessStrategy] = useState(null);
const [IPSalesCommerce, setIPSalesCommerce] = useState(null);
const [IPTechnologyInnovation, setIPTechnologyInnovation] = useState(null);
const [IPSupplyChain, setIPSupplyChain] = useState(null);
const [IPBusinessProcess, setIPBusinessProcess] = useState(null);
const [IPApplicationServices, setIPApplicationServices] = useState(null);
const [IPHighProfilePR, setIPHighProfilePR] = useState(null);
////////////////LAYOUT4////////////////////////////////////////////////
const [IPCard1, setIPCard1] = useState(null);
const [IPCard2, setIPCard2] = useState(null);
const [IPCard3, setIPCard3] = useState(null);
//Aq deifned states end

//Aq Developer code
const fetchAllData = async () => {
  setLoading(true);
  try {
    const fetchData = async (endpoint) => {
      const response = await fetch(`${SERVER_URL}/api${endpoint}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      return response.json();
    };

    // Fetch all data and update respective states
    const responses = await Promise.all([
      fetchData("/layout1/indexPageHero").then((data) => setIndexPageHero(data?.data?.[0])),
      fetchData("/user/other/indexPageCrousal").then((data) => setIndexPageCarousal(data.data)),
      fetchData("/layout1/indexPageSec2").then((data) => setIndexPageSec2(data?.data?.[0])),
      fetchData("/layout1/indexPageAbousUs1").then((data) => setIndexPageAbousUs1(data?.data?.[0])),
      fetchData("/layout1/indexPageAbousUs2").then((data) => setIndexPageAbousUs2(data?.data?.[0])),
      fetchData("/layout1/indexPageAbousUsB1").then((data) => setIndexPageAbousUsB1(data?.data?.[0])),
      fetchData("/layout1/indexPageAbousUsB2").then((data) => setIndexPageAbousUsB2(data?.data?.[0])),
      fetchData("/layout1/indexPageAbousUsB3").then((data) => setIndexPageAbousUsB3(data?.data?.[0])),
      fetchData("/layout1/indexPageOurService").then((data) => setIndexPageOurService(data?.data?.[0])),
      fetchData("/layout1/indexPageOurServiceHeadingOnly").then((data) => setindexPageOurServiceHeadingOnly(data?.data?.[0])),
      fetchData("/layout1/indexPageITSol").then((data) => setindexPageITSol(data?.data?.[0])),
      fetchData("/layout1/indexPageLastText").then((data) => setindexPageLastText(data?.data?.[0])),
      fetchData("/layout1/indexPageLastSection").then((data) => setindexPageLastSection(data?.data?.[0])),
//8 section handling
      fetchData("/layout1/indexPageITProducts").then((data) => setIndexPageITProducts(data?.data?.[0])),
      fetchData("/layout1/indexPageBusinessStrategy").then((data) => setIndexPageBusinessStrategy(data?.data?.[0])),
      fetchData("/layout1/indexPageDigitalCommerce").then((data) => setIndexPageDigitalCommerce(data?.data?.[0])),
      fetchData("/layout1/indexPageTechnologyInnovation").then((data) => setIndexPageTechnologyInnovation(data?.data?.[0])),
      fetchData("/layout1/indexPageSupplyChainManagement").then((data) => setIndexPageSupplyChainManagement(data?.data?.[0])),
      fetchData("/layout1/indexPageBusinessProcessOutsourcing").then((data) => setIndexPageBusinessProcessOutsourcing(data?.data?.[0])),
      fetchData("/layout1/indexPageApplicationServices").then((data) => setIndexPageApplicationServices(data?.data?.[0])),
      fetchData("/layout1/indexPageHighProfilePR").then((data) => setIndexPageHighProfilePR(data?.data?.[0])),
      //Layout 2
      fetchData("/layout2/IPITProducts").then((data) => setIPITProducts(data?.data?.[0])),
        fetchData("/layout2/IPBusinessStrategy").then((data) => setIPBusinessStrategy(data?.data?.[0])),
        fetchData("/layout2/IPSalesCommerce").then((data) => setIPSalesCommerce(data?.data?.[0])),
        fetchData("/layout2/IPTechnologyInnovation").then((data) => setIPTechnologyInnovation(data?.data?.[0])),
        fetchData("/layout2/IPSupplyChain").then((data) => setIPSupplyChain(data?.data?.[0])),
        fetchData("/layout2/IPBusinessProcess").then((data) => setIPBusinessProcess(data?.data?.[0])),
        fetchData("/layout2/IPApplicationServices").then((data) => setIPApplicationServices(data?.data?.[0])),
        fetchData("/layout2/IPHighProfilePR").then((data) => setIPHighProfilePR(data?.data?.[0])),
      //Layout 3
      fetchData("/layout4/IPCard1").then((data) => setIPCard1(data?.data?.[0])),
      fetchData("/layout4/IPCard2").then((data) => setIPCard2(data?.data?.[0])),
      fetchData("/layout4/IPCard3").then((data) => setIPCard3(data?.data?.[0])),
    ]);

    console.log("All data fetched successfully:", responses);
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false); // Stop loading
  }
};

useEffect(() => {
  fetchAllData();
}, []);
//Aq developer code end

  useEffect(() => {
    if (code) {
      if (code === "aboutus") {
        aboutRef.current.scrollIntoView({
          behavior: "smooth",
        });
      } else if (code === "service") {
        serviceRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [code]);

  return (
   <>
    {loading?(<div className="flex flex-col h-[50vh] items-center justify-center">
    <img src={spinner} className="h-24 w-44" alt="Loading" />
  </div>):(
    <div>
      <NavBar
        code={code}
        onAboutPress={() =>
          aboutRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        }
        onServicePress={() =>
          serviceRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        }
      />
      <DynamicDiv data={indexPageHero} dataImg={indexPageCarousal}/>
      <div ref={aboutRef}>
        <AboutUs data1={indexPageSec2} data2={indexPageAbousUs1} data3={indexPageAbousUs2} data4={indexPageAbousUsB1} data5={indexPageAbousUsB2} data6={indexPageAbousUsB3}/>
      </div>
      <div ref={serviceRef}>
        <Services heading={indexPageOurServiceHeadingOnly} 
        sec1={indexPageITProducts}
        sec2={indexPageBusinessStrategy}
        sec3={indexPageDigitalCommerce}
        sec4={indexPageTechnologyInnovation}
        sec5={indexPageSupplyChainManagement}
        sec6={indexPageBusinessProcessOutsourcing}
        sec7={indexPageApplicationServices}
        sec8={indexPageHighProfilePR}
          onITProduct={() =>
            initialRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
          onBusiness={() =>
            oneRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
          onDigital={() =>
            secondRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
          onTech={() =>
            thirdRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
          onSupply={() =>
            forthRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
          onProcess={() =>
            fifthRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
          onApplication={() =>
            sixRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
          onHigh={() =>
            sevenRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
        />
      </div>

      <div ref={initialRef}>
        <Initial sec1={indexPageITProducts} content={IPITProducts&&IPITProducts.data} />
      </div>
      <div ref={oneRef}>
        <One sec2={indexPageBusinessStrategy} content={IPBusinessStrategy&&IPBusinessStrategy.data}/>
      </div>

      <div ref={secondRef}>
        <Second sec3={indexPageDigitalCommerce} content={IPSalesCommerce&&IPSalesCommerce.data} />
      </div>

      <div ref={thirdRef}>
        <Third sec4={indexPageTechnologyInnovation} content={IPTechnologyInnovation&&IPTechnologyInnovation.data}/>
      </div>
      <div ref={forthRef}>
        <Forth sec5={indexPageSupplyChainManagement} content={IPSupplyChain&&IPSupplyChain.data}/>
      </div>

      <div ref={fifthRef}>
        <Fifth sec6={indexPageBusinessProcessOutsourcing} content={IPBusinessProcess&&IPBusinessProcess.data} />
      </div>

      <div ref={sixRef}>
        <Six sec7={indexPageApplicationServices} content={IPApplicationServices&&IPApplicationServices.data} />
      </div>
      <div ref={sevenRef}>
        <Seven sec8={indexPageHighProfilePR} content={IPHighProfilePR&&IPHighProfilePR.data} />
      </div>

      <IT heading={indexPageITSol} 
      Card1={IPCard1}
      Card2={IPCard2}
      Card3={IPCard3} 
      lastText={indexPageLastText}
      lastSection={indexPageLastSection}
      />

      {/* <PerfectSolution /> */}
      <Footer
        code={code}
        onAboutPress={() =>
          aboutRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        }
        onServicePress={() =>
          serviceRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        }
      />
    </div>
  )}
</> 
  );
};

export default Home;
